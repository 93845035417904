import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
@Component({
    selector: 'app-supplying-menu',
    templateUrl: './supplying-menu.component.html',
})
export class SupplyingMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit(): void {
        if (this.getAuth(['MANAGE_SUGGESTED'])) {
            this.model.push({
                label: 'Administración',
                icon: '',
                routerLink: [`/abastecimiento/manage`],
            });
        }

        if (this.getAuth(['SETTINGS'])) {
            this.model.push({
                label: 'Tareas',
                icon: '',
                routerLink: [`/supplying-config/schedule-task`],
            });
        }
        if (this.getAuth(['MANAGE_ASSIGMENT'])) {
            this.model.push({
                label: 'Asignación Recursos',
                icon: '',
                routerLink: [`/supplying-assigment/resources`],
            });
        }

        if (this.getAuth(['MANAGE_NEW_PRODUCTS'])) {
            this.model.push({
                label: 'Nuevos Productos',
                icon: '',
                routerLink: [`/new-products`],
            });
        }

        if (this.getAuth(['MANAGE_RESTRICTIONS'])) {
            this.model.push({
                label: 'Restricciones',
                icon: '',
                routerLink: [`/restriction/quantity-restriction`],
            });
        }

        if (this.getAuth(['MANAGE_REPORTS'])) {
            this.model.push({
                label: 'Reportes',
                icon: '',
                routerLink: [`/supplying-reports/stock`],
            });
        }

  /*       if (this.getAuth(['MANAGE_REPORTS'])) {
            this.model.push({
                label: 'Reabastecimiento',
                icon: '',
                routerLink: [`/re-supplier/config`],
            });
        } */
    }
}
