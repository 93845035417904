<div class="container">
    <div class="row">
        <div class="col">
            <strong> Usuario: </strong> {{user?.person?.fullName}}
        </div>
    </div>
    <div class="pt-2">
        <p-table #dt1 [value]="usersLogs" selectionMode="single" dataKey="name" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr>
                    <th id="client">
                        Usuario
                    </th>
                    <th id="created">
                        Modificado por
                    </th>
                    <th id="updated">
                        Actualización
                    </th>
                    <th id="total">
                        Tipo
                    </th>
                    <th id="number">Fecha</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-userlog let-rowIndex="rowIndex">
                <tr [pSelectableRow]="docSuggested">
                    <td>{{userlog.user.userName | uppercase}}</td>
                    <td>{{userlog.transactionBy | uppercase}}</td>
                    <td>{{userlog.transaction}}</td>
                    <td>
                        <label *ngIf="
                        userlog.transactionUserLogType == 'ENABLED'
                    " class="label lbl-p">ACTIVACIÓN</label>
                        <label *ngIf="
                        userlog.transactionUserLogType == 'UPDATE'
                    " class="label lbl-s">ACTUALIZACIÓN</label>
                        <label *ngIf="
                        userlog.transactionUserLogType == 'DISABLED'
                    " class="label lbl-c">DESACTIVACIÓN</label>
                    </td>
                    <td>{{userlog.updatedAt | date: 'dd/MM/yyyy h:mm'}} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No se ha encontrado registros.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="row justify-content-center">
            <span class="p-paginator-current ng-star-inserted">Registros {{ pageSince }} de {{ pageUntil }} del total
                {{ totalElements }}</span>
            <p-paginator #paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)">
            </p-paginator>
            <p-dropdown class="pt-3" [options]="elements" [(ngModel)]="size" [showClear]="false"
                (onChange)="listUsersLogs()"></p-dropdown>
        </div>
    </div>
</div>
<p-confirmDialog *ngIf="" [style]="{width: '450px'}"></p-confirmDialog>