import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { UserInput, UserPasswordInput } from 'src/app/model.ts/user.model';
import { CustomValidationService } from 'src/app/services/custom-validation.service';

import { AuthService } from '../../../../core/auth.service';
import { ChangePasswordView } from './change-password.view';
import { ChangePasswordPresenter } from './presenter/change-password.presenter';
import { UserLogInput } from 'src/app/model.ts/user-log.model';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent
    extends AbstractView
    implements OnInit, ChangePasswordView {
    constructor(
        private authService: AuthService,
        public ref: DynamicDialogRef,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        private messageService: MessageService,
        private customValidator: CustomValidationService,
        private confirmationService: ConfirmationService,
        private changePasswordresenter: ChangePasswordPresenter,
        public router: Router
    ) {
        super(messageService, router);
        changePasswordresenter.view = this;
        this.user = config.data.user;
        this.isAdmin = config.data.isAdmin;
        this.currentUser = config.data.currentUser;
    }

    isAdmin = false;
    visiblePassword = true;
    visibleOldPassword = true;
    visibleConfirmPassword = true;
    user: UserInput = {};
    passwordForm = this.formBuilder.group({});
    errorMessage: string;
    submitted = false;
    userLog: UserLogInput = {};
    currentUser: any = {};
    ngOnInit(): void {
        this.passwordForm = this.formBuilder.group(
            {
                oldPassword: ['', [Validators.required]],
                password: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.customValidator.patternValidator(),
                    ]),
                ],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validator: this.customValidator.MatchPassword(
                    'password',
                    'confirmPassword'
                ),
            }
        );
    }

    get registerFormControl() {
        return this.passwordForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        const oldPassword = this.passwordForm.get('oldPassword').value;
        if (this.isAdmin) {
            this.passwordForm
                .get('oldPassword')
                .setValue(oldPassword ? oldPassword : 'noPassword');
        }

        if (this.passwordForm.valid) {
            if (this.isAdmin) {
                // only admin can change password of users
                const userChange: UserPasswordInput = {};
                userChange.id = this.user.id;
                userChange.userName = this.user.userName;
                userChange.resetPassword = true;
                userChange.oldPassword = null;
                userChange.password = this.passwordForm.get('password').value;
                this.showConfirmation(userChange);
            } else {
                this.passwordForm.get('oldPassword').setValue(oldPassword);
                const user: UserInput = {};
                user.id = this.user.id;
                user.userName = this.user.userName;
                user.password = this.passwordForm.get('password').value;
                user.resetPassword = false;
                user.oldPassword = oldPassword;
                this.showConfirmationInside(user);
            }
        } else {
            this.showWarn('Atención', 'Información incompleta');
        }
    }

    showConfirmation(user: UserPasswordInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: '¿Desea cambiar la contraseña?',
            header: `Confirme ${this.isAdmin ? 'reinicio' : 'cambio'
                } de contraseña`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.changePasswordresenter.changePassword(user);
            },
        });
    }
    showConfirmationInside(user: UserInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: '¿Desea cambiar la contraseña?',
            header: `Confirme ${this.isAdmin ? 'reinicio' : 'cambio'
                } de contraseña`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.changePasswordresenter.changePasswordInside(user);
            },
        });
    }

    closeView() {
        this.ref.close();
    }
}
