import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { UserInput, UserPasswordInput } from '../../../../../model.ts/user.model';
import { ChangePasswordView } from '../change-password.view';
import { TransactionUserLogType } from 'src/app/model.ts/user-log.model';

@Injectable()
export class ChangePasswordPresenter implements Presenter {
    view: ChangePasswordView;

    constructor(
        private usersRolesPermissionsService: UsersRolesPermissionsService
    ) { }

    changePassword(user: UserPasswordInput) {
        this.view.blockUi();

        this.view.userLog = {
            user: this.usersRolesPermissionsService.omitType(this.view.user),
            transactionBy: this.view.currentUser.userName,
            transaction: 'CONTRASEÑA actualizado',
            transactionUserLogType: TransactionUserLogType.UPDATE
        };
        this.usersRolesPermissionsService.updatePassword(user).subscribe(
            () => {
                this.view.showSuccess('Actualización', 'Actualizado con éxito admin');
                this.view.closeView();
                this.usersRolesPermissionsService.saveUserLog(this.view.userLog).subscribe();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    changePasswordInside(user: UserInput) {
        this.view.blockUi();
        this.usersRolesPermissionsService.updatePasswordInside(user).subscribe(
            () => {
                this.view.showSuccess('Actualización', 'Actualizado con éxito inside');
                this.view.closeView();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
