import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { UserInput } from 'src/app/model.ts/user.model';
import { EstablishmentService } from 'src/app/services/establishment.service';
import { PersonService } from 'src/app/services/person.service';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { GenerateUserView } from '../generate-user.view';
import { TransactionUserLogType } from 'src/app/model.ts/user-log.model';

@Injectable()
export class GenerateUserPresenter implements Presenter {
    view: GenerateUserView;

    constructor(
        private establishmentService: EstablishmentService,
        private usersRolesPermissionsService: UsersRolesPermissionsService,
        private systemsRolesPermissionsService: SystemsRolesPermissionsService,
        private personService: PersonService
    ) {}

    listSystems() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(0, 100, '', true)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.systems = data.systems.data;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listWarehousesByValue() {
        this.view.blockUi();
        this.establishmentService.listWarehousesByValue('').subscribe(
            ({ data }) => {
                if (data) {
                    this.view.warehouses = data.warehouseByValue;
                } else {
                    this.view.showWarn(
                        'Atención',
                        'No existen bodegas en la base de datos'
                    );
                }
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    listRolesBySystemId() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listRolesBySystemId(0, 100, true, this.view.selectedSystem.id)
            .subscribe(
                ({ data }) => {
                    this.view.roles = data.rolBySystem.data;
                    this.view.setSystemToRoles();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    saveUser(user: UserInput) {
        this.view.userLog = {
            user,
            transactionBy: this.view.currentUser.userName,
            transaction: this.view.logsUpdate.toString(),
            transactionUserLogType: TransactionUserLogType.UPDATE
        };
        this.view.blockUi();
        this.usersRolesPermissionsService.saveUser(user).subscribe(
            ({ data }) => {
                this.view.showSuccess('Usuario', 'Guardado con éxito');
                // localStorage.removeItem('currentPerson');
                this.view.closeView();
                if (this.view.logsUpdate.length > 0) {
                    this.usersRolesPermissionsService.saveUserLog(this.view.userLog).subscribe();
                }
                if (this.view.isDisabledUser) {
                    this.view.userLog.transaction = `Usuario ${user.active ? 'ACTIVO' : 'INACTIVO'} actualizado`;
                    this.view.userLog.transactionUserLogType = user.active ? TransactionUserLogType.ENABLED
                        : TransactionUserLogType.DISABLED;
                    this.usersRolesPermissionsService.saveUserLog(this.view.userLog).subscribe();
                }
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    userById(id) {
        this.view.blockUi();
        this.usersRolesPermissionsService.userById(id).subscribe(
            ({ data }) => {
                this.view.selectedUser = data.userById;
                this.view.selectedPerson = this.view.selectedUser.person;
                this.view.rolesSelected = this.view.selectedUser.roles;
                this.view.warehouseSelected = this.view.selectedUser.warehouse;
                this.view.validateSelectedUser();
                this.view.setPermissionsToTree();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
    personById(id) {
        this.view.blockUi();
        this.personService.personById(id).subscribe(
            ({ data }) => {
                this.view.selectedPerson = data.personById;
                this.view.validateSelectedUser();
                this.view.setPermissionsToTree();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
