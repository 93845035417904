import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
    ConfigurationType,
    ConfigUserDynamicInput,
} from '../model.ts/user-dynamics.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigDynamicsService {
    CONFIG_USER_DYNAMICS = gql`
        query findByConfUserConfigurationType ($typeConfig: ConfigurationType) {
            findByConfUserConfigurationType(typeConfig: $typeConfig) {
                id
                grantType
                clientId
                clientSecret
                resource
                type
            }
        }
    `;

    SAVE_CONFIG_USER_DYNAMICS = gql`
        mutation saveConfUserDynamics(
            $confUserDynamics: ConfigUserDynamicInput
        ) {
            saveConfUserDynamics(confUserDynamics: $confUserDynamics) {
                id
                grantType
                clientId
                clientSecret
                resource
                type
            }
        }
    `;

    SYNC_STOCK_DYNAMICS = gql`
        mutation syncStock {
            syncStock
        }
    `;
    constructor(private apollo: Apollo) { }

    omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    omitType(model) {
        return JSON.parse(JSON.stringify(model), this.omitTypename);
    }

    findByConfUserConfigurationType(typeConfig: ConfigurationType) {
        return this.apollo.query<any>({
            query: this.CONFIG_USER_DYNAMICS,
            variables: { typeConfig },
        });
    }

    saveConfUserDynamics(confUserDynamics: ConfigUserDynamicInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_CONFIG_USER_DYNAMICS,
            variables: { confUserDynamics },
        });
    }
    syncStockDynamics() {
        return this.apollo.mutate({
            mutation: this.SYNC_STOCK_DYNAMICS,
            variables: {},
        });
    }
}
