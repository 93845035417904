import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private apollo: Apollo,
        private http: HttpClient,
        private auth: AuthService
    ) {}

    PERSON_BY_USERNAME = gql`
        query findPersonByUserName($username: String!) {
            findPersonByUserName(username: $username) {
                id
                fullName
                dni
                email
                active
                referenceId
                phone
                identificationType
                user {
                    id
                    userName
                    password
                    pin
                    warehouse {
                        id
                        name
                        referenceId
                        active
                        establishment {
                            id
                            name
                            address
                            number
                            referenceId
                        }
                    }
                    active
                    resetPassword
                    roles {
                        id
                        name
                        active
                        permissions {
                            id
                            name
                            domainAction
                            active
                        }
                    }
                    oldPassword
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                }
                userName
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    `;

    doLoginRest(userName: string, password: string, system: string) {
        return this.http.post(
            environment.apiUrl + '/login',
            JSON.stringify({ userName, password, system })
        );
    }

    getCurrentPerson() {
        const username = this.auth.getCurrentSub();
        return this.apollo.query<any>({
            query: this.PERSON_BY_USERNAME,
            variables: { username },
        });
    }
    getPersonByUserName(username: string) {
        return this.apollo.query<any>({
            query: this.PERSON_BY_USERNAME,
            variables: { username },
        });
    }
}
