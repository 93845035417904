import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

import {
    EstablishmentDirectoryInput,
    EstablishmentInput,
    WarehouseInput
} from '../model.ts/establishment.model';

@Injectable({
    providedIn: 'root',
})
export class EstablishmentService {
    LIST_ESTABLISHMENT_BY_ID = gql`
        query establishmentById($id: ID!) {
            establishmentById(id: $id) {
                id
                name
                address
                number
                phone
                referenceId
                createdAt
                createdBy
                updatedAt
                updatedBy
                active
            }
        }
    `;

    LIST_ESTABLISHMENTS = gql`
        query establishments($page: Int!, $size: Int!, $search: String) {
            establishments(page: $page, size: $size, search: $search) {
                page
                size
                totalElements
                totalPages
                data {
                    id
                    name
                    address
                    number
                    phone
                    referenceId
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    active
                }
            }
        }
    `;
    LIST_ESTABLISHMENT_BY_VALUE = gql`
        query establishmentByValue($value: String!) {
            establishmentByValue(value: $value) {
                id
                name
                address
                number
                phone
                referenceId
                createdAt
                createdBy
                updatedAt
                updatedBy
                active
            }
        }
    `;
    /*  LIST_ESTABLISHMENT_ASSIGNMENT = gql`
        query establishmentByValue($value: String!) {
            establishmentByValue(value: $value) {
                id
                name
                number
                code
                active
                supply
            }
        }
    `; */
    /* LIST_ESTABLISHMENT_TO_SUPPLY = gql`
        query establishmentByValue($value: String!) {
            establishmentByValue(value: $value) {
                id
                name
                address
                latitude
                longitude
                number
                phone
                key
                email
                ip
                valuedMaximumStock
                createdDate
                lastModifiedDate
                code
                active
                acopio
                supply
                sector {
                    id
                    name
                    city {
                        id
                        name
                        state {
                            id
                            name
                        }
                    }
                }
                zone {
                    id
                    name
                }
                establishmentReferenceId
            }
        }
    `; */
    /* ESTABLISHMENT_BY_VALUE = gql`
        query establishmentByValue($value: String!) {
            establishmentByValue(value: $value) {
                id
                name
                address
                latitude
                longitude
                number
                phone
                key
                email
                ip
                valuedMaximumStock
                createdDate
                lastModifiedDate
                code
                active
                acopio
                supply
                establishmentReferenceId
            }
        }
    `; */
    /* LIST_ESTABLISHMENT_REPORT = gql`
        {
            establishment {
                id
                name
                address
                latitude
                longitude
                phone
                number
                key
                ip
                valuedMaximumStock
                createdDate
                lastModifiedDate
                code
                active
                acopio
                supply
                sector {
                    id
                    name
                }
                zone {
                    id
                    name
                }
            }
        }
    `; */
    LIST_ESTABLISHMENTBYCODE = gql`
        query establishmentByCode($establishmentCode: String!) {
            establishmentByCode(establishmentCode: $establishmentCode) {
                id
                name
                address
                number
                phone
                referenceId
                createdAt
                createdBy
                updatedAt
                updatedBy
                active
            }
        }
    `;

    /**
     * ! Update Dynamics
     */
    WAREHOUSES_LIST = gql`
        query warehouses($page: Int!, $size: Int!, $search: String) {
            warehouses(page: $page, size: $size, search: $search) {
                page
                size
                totalElements
                totalPages
                data {
                    id
                    name
                    address
                    phone
                    referenceId
                    email
                    valuedMaximumStock
                    active
                    acopio
                    supply
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    establishment {
                        id
                        name
                        number
                        address
                        referenceId
                    }
                    directTransfer
                }
            }
        }
    `;
    /**
     * ! Update Dynamics
     */
    WAREHOUSE_BY_ID = gql`
        query warehouseById($warehouseId: ID!) {
            warehouseById(warehouseId: $warehouseId) {
                id
                name
                address
                phone
                referenceId
                email
                valuedMaximumStock
                active
                acopio
                supply
                createdAt
                updatedAt
                createdBy
                updatedBy
                establishment {
                    id
                    name
                    number
                    referenceId
                }
                directTransfer
            }
        }
    `;
    /**
     * ! Update Dynamics
     */
    SAVE_WAREHOUSE = gql`
        mutation saveWarehouse($warehouse: WarehouseInput) {
            saveWarehouse(warehouse: $warehouse) {
                id
                name
                address
                phone
                referenceId
                email
                valuedMaximumStock
                active
                acopio
                supply
                createdAt
                updatedAt
                createdBy
                updatedBy
                establishment {
                    id
                    name
                    number
                    referenceId
                }
            }
        }
    `;

    LIST_WAREHOUSES_BY_VALUE = gql`
        query warehouseByValue($value: String!) {
            warehouseByValue(value: $value) {
                id
                name
                address
                phone
                referenceId
                email
                valuedMaximumStock
                active
                acopio
                supply
                createdAt
                updatedAt
                createdBy
                updatedBy
                establishment {
                    id
                    name
                    address
                    number
                    phone
                    referenceId
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                }
                directTransfer
            }
        }
    `;

    LIST_WAREHOUSES_BY_VALUE_ASSIGNMENT = gql`
        query warehouseByValue($value: String!) {
            warehouseByValue(value: $value) {
                id
                name
                referenceId
                active
                supply
            }
        }
    `;

    LIST_DIRECTORY_ESTABLISHMENTS = gql`
        query directoriesBySearch ($page: Int!, $size: Int!, $search: String){
            directoriesBySearch (page: $page, size: $size, search: $search) {
                page
                size
                totalElements
                totalPages
                data {
                    directoryId
                    warehouse {
                        id
                        name
                        address
                        phone
                        referenceId
                        email
                        valuedMaximumStock
                        createdAt
                        updatedAt
                        createdBy
                        updatedBy
                        directTransfer
                        supply
                        establishment {
                            number
                            name
                            address
                            id
                        }
                    }
                    category
                    ubicationGoogleMap
                    regional
                    region
                    province
                    city
                    sector
                    specialization
                    area
                    warrantyCash
                    warrantyInventory
                    monetization
                    franchisee
                    franchiseeRuc
                    dateBirthFranchisee
                    phoneFranchisee
                    emailFranchisee
                    phoneMegaprofer
                    phoneSeller
                    webPage
                    instagram
                    facebook
                    tiktok
                    linkedin
                    youtube
                    businessManager
                    emailBusinessManager
                    advisor
                    regionalSupervisor
                    openingDate
                    extension
                    openingHoursWeek
                    openingHoursWeekend
                    openingHoursHoliday
                    relevantNotes
                    createdAt
                    updatedAt
                    updatedBy
                }
            }
        }
    `;

    SAVE_DIRECTORY_ESTABLISHMENTS = gql`
    mutation saveDirectories ($directoryEstablishment: DirectoryEstablishmentInput) {
        saveDirectories(directoryEstablishment: $directoryEstablishment) {
            directoryId
            warehouse {
                    id
                    name
                    address
                    phone
                    referenceId
                    email
                    valuedMaximumStock
                    active
                    acopio
                    supply
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    establishment {
                    number
                    name
                }
                directTransfer
            }
            category
            ubicationGoogleMap
            regional
            region
            province
            city
            sector
            specialization
            area
            warrantyCash
            warrantyInventory
            monetization
            franchisee
            franchiseeRuc
            dateBirthFranchisee
            phoneFranchisee
            emailFranchisee
            phoneMegaprofer
            phoneSeller
            webPage
            instagram
            facebook
            tiktok
            linkedin
            youtube
            businessManager
            emailBusinessManager
            advisor
            regionalSupervisor
            openingDate
            extension
            openingHoursWeek
            openingHoursWeekend
            openingHoursHoliday
            relevantNotes
            createdAt
            updatedAt
            updatedBy
        }
    }
    `;

    constructor(private apollo: Apollo) { }

    typeName(key, value) {
        return key === '__typename' ? undefined : value;
    }

    omitType(model) {
        return JSON.parse(JSON.stringify(model), this.typeName);
    }

    listEstablishments(page: number, size: number, search: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENTS,
            variables: { page, size, search },
        });
    }
    listEstablishmentByValue(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENT_BY_VALUE,
            variables: { value },
        });
    }

    /* listEstablishmentAssignment(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENT_ASSIGNMENT,
            variables: { value },
        });
    } */

    /* listEstablishmentToSupply(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENT_TO_SUPPLY,
            variables: { value },
        });
    }
 */
    /*   establishmentByValue(value: string) {
        return this.apollo.query<any>({
            query: this.ESTABLISHMENT_BY_VALUE,
            variables: { value },
        });
    }
 */
    /*  listEstablishmentsReport() {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENT_REPORT,
        });
    } */

    listEstablishmentById(id: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENT_BY_ID,
            variables: { id },
        });
    }
    listEstablishmentByCode(establishmentCode: string) {
        return this.apollo.query<any>({
            query: this.LIST_ESTABLISHMENTBYCODE,
            variables: { establishmentCode },
        });
    }

    warehouses(page: number, size: number, search: string) {
        return this.apollo.query<any>({
            query: this.WAREHOUSES_LIST,
            variables: {
                page,
                size,
                search,
            },
        });
    }

    warehouseById(warehouseId: string) {
        return this.apollo.query<any>({
            query: this.WAREHOUSE_BY_ID,
            variables: { warehouseId },
        });
    }

    saveWarehouse(warehouse: WarehouseInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_WAREHOUSE,
            variables: { warehouse },
        });
    }

    listWarehousesByValue(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_WAREHOUSES_BY_VALUE,
            variables: { value },
        });
    }

    listWarehousesByValueAssignment(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_WAREHOUSES_BY_VALUE_ASSIGNMENT,
            variables: { value },
        });
    }

    listEstablishmentDirectory(page: number, size: number, search: string) {
        return this.apollo.query<any>({
            query: this.LIST_DIRECTORY_ESTABLISHMENTS,
            variables: { page, size, search },
        });
    }

    saveEstablishmentDirectory(establishmentDirectoryInput: EstablishmentDirectoryInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_DIRECTORY_ESTABLISHMENTS,
            variables: { directoryEstablishment: establishmentDirectoryInput },
        });
    }
}
