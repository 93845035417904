import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PersonService } from 'src/app/services/person.service';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { UsersView } from '../users.view';

@Injectable()
export class UsersPresenter implements Presenter {
    view: UsersView;

    constructor(
        public personService: PersonService,
        public usersRolesPermissionsService: UsersRolesPermissionsService
    ) {}

    listUsers(active: boolean) {
        this.view.blockUi();
        this.usersRolesPermissionsService
            .listUsersByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                active
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.totalElements = data.users.totalElements;
                        this.view.totalPages = data.users.totalPages;
                        this.view.users = data.users.data;
                        this.view.size = data.users.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen usuarios en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    userById(userId: string) {
        this.view.blockUi();
        this.usersRolesPermissionsService.userById(userId).subscribe(
            ({ data }) => {
                this.view.selectedUser = data.userById;
                this.view.showGenerateUserComponent(
                    'Editar Usuario ' + this.view.selectedUser.userName,
                    false
                );
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    getNumberUsers() {
        this.view.blockUi();
        this.usersRolesPermissionsService
            .usersStatusCount(!this.view.activeUser)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberUsers = data.usersStatusCount;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen usuarios cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    resynchronizeUsers() {
        this.view.blockUi();
        this.usersRolesPermissionsService.resynchronizeUsers().subscribe(
            (data) => {
                this.view.showWarn(
                    'Correcto',
                    'Se ha sincronizado los usuarios.'
                );
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
