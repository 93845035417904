<div class="exception-body accessdenied-body">
    <div class="exception-panel">
        <img src="assets/layout/images/exception/icon-access.png" />

        <p-button label="IR A LOGIN" [routerLink]="['/']"></p-button>
    </div>

    <div class="exception-band">
        <div class="exception-content">
            <h3>ACCESO DENEGADO</h3>
            <p>Expiró el tiempo para restablecer su contraseña</p>
            <img src="assets/layout/images/login/logo_login.png" />
        </div>
    </div>
</div>
