import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    ConfirmationService,
    MenuItem,
    MessageService,
    PrimeNGConfig,
} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Person } from 'src/app/model.ts/person.model';
import { Rol } from 'src/app/model.ts/rol.model';
import {
    ConfigurationType,
    ConfigUserDynamicInput,
} from 'src/app/model.ts/user-dynamics.model';
import { ChangePasswordComponent } from 'src/app/modules/user-manage/components/change-password/change-password.component';
import { DataProvider } from 'src/app/services/data.provider';
import { environment } from 'src/environments/environment';

import { AppMainComponent } from '../main/app.main.component';
import { AppTopbarView } from './app.topbar.view';
import { AppTopbarPresenter } from './presenter/app.topbar.presenter';
import { IndexedDBService } from 'src/app/services/indexeddb';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopbarComponent
    extends AbstractView
    implements OnInit, AppTopbarView {
    currentPerson: Person = {};
    roles = '';
    rolesProfile = [];
    visiblePassword = true;
    items: MenuItem[];
    displayPosition: boolean;
    position: string;
    displayModal: boolean;
    password: string;
    system = environment.system;
    public ref;
    displayConfigDynamics = false;
    selectedConfigUserDynamic: ConfigUserDynamicInput;
    frmConfigDynamics: FormGroup;
    submitted = false;
    isDevelop = false;
    displaySyncStock = false;

    constructor(
        public router: Router,
        public app: AppMainComponent,
        public dataProvider: DataProvider,
        public dialogService: DialogService,
        public messageService: MessageService,
        private appTopbarPresenter: AppTopbarPresenter,
        private confirmationService: ConfirmationService,
        private primengConfig: PrimeNGConfig,
        private formBuilder: FormBuilder,
        private indexedDBService: IndexedDBService
    ) {
        super(messageService, router);
        appTopbarPresenter.view = this;

        this.frmConfigDynamics = this.formBuilder.group({
            type: [{ value: '', disabled: true }, [Validators.required]],
            grantType: [{ value: '', disabled: true }, [Validators.required]],
            clientId: [{ value: '', disabled: true }, [Validators.required]],
            clientSecret: [
                { value: '', disabled: true },
                [Validators.required],
            ],
            resource: [{ value: '', disabled: true }, [Validators.required]],
        });

        if (this.getAuth(['AUTH_DYNAMICS'])) {
            this.isDevelop = true;
            // console.log('isDevelop', this.isDevelop);
        }
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.appTopbarPresenter.getCurrentPerson();

        this.items = [
            {
                label: 'Opciones',
                items: [
                    {
                        label: 'Cambiar contraseña',
                        icon: 'pi pi-key',
                        command: () => {
                            this.changePassword(true, '');
                        },
                    },
                    {
                        label: 'Roles',
                        icon: 'pi pi-id-card',
                        command: () => {
                            this.showPositionDialog('top-right');
                        },
                    },
                    {
                        label: 'Dynamics',
                        icon: 'pi pi-share-alt',
                        visible: this.isDevelop,
                        command: () => {
                            this.showConfigDynamicsDialog();
                        },
                    },
                    {
                        label: 'Sync Stock',
                        icon: 'pi pi-cloud-download',
                        visible: this.isDevelop,
                        command: () => {
                            this.displaySyncStock = true;
                        },
                    },
                    {
                        label: 'Salir',
                        icon: 'pi pi-sign-out',
                        command: () => {
                            this.logout();
                        },
                    },
                ],
            },
        ];
    }

    verifyPassword() {
        this.appTopbarPresenter.verifyPassword(
            this.currentPerson.user.userName,
            this.password,
            this.system
        );
        this.password = '';
        this.displayModal = false;
    }

    permissions() {
        this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Updated',
        });
    }

    logout() {
        this.blockUi();
        localStorage.clear();
        const status = localStorage.getItem('authorization');
        if (status == null) {
            // window.location.reload();
            this.indexedDBService.deleteDatabase();
            this.redirectTo('/login');
            window.location.href = window.location.href;
        }
    }

    syncStockDynamics() {
        this.appTopbarPresenter.syncStockDynamics();
    }

    showPositionDialog(position: string) {
        this.position = position;
        this.displayPosition = true;
    }

    showConfigDynamicsDialog() {
        this.displayConfigDynamics = true;
        this.appTopbarPresenter.findByConfUserConfigurationType(
            ConfigurationType.DYNAMICS
        );
    }

    showConfirmationLogOut() {
        this.confirmationService.confirm({
            acceptLabel: 'Salir',
            rejectLabel: 'Cancelar',
            message: '¿Desea salir del sistema?',
            header: 'Información',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.logout();
            },
        });
    }

    setRoles(roles: Rol[]) {
        if (roles.length > 0) {
            roles.forEach((r) => {
                this.rolesProfile.push(r);
                this.roles = this.roles + '- ' + r.name + '\n';
            });
            this.roles = '\n' + this.roles;
        } else {
            this.roles = 'Usuario sin roles';
        }
    }

    changePassword(escape: boolean, message: string) {
        this.ref = this.dialogService.open(ChangePasswordComponent, {
            header: 'CAMBIAR CONTRASEÑA ' + message,
            closeOnEscape: escape,
            closable: escape,
            data: { user: this.currentPerson.user, isAdmin: false },
        });
        this.ref.onClose.subscribe((res) => {
            this.dataProvider.isDisabledScreen = false;
        });
    }

    addValuesForm() {
        this.frmConfigDynamics.patchValue({
            type: this.selectedConfigUserDynamic.type,
            grantType: this.selectedConfigUserDynamic.grantType,
            clientId: this.selectedConfigUserDynamic.clientId,
            clientSecret: this.selectedConfigUserDynamic.clientSecret,
            resource: this.selectedConfigUserDynamic.resource,
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.frmConfigDynamics.invalid) {
            return;
        }
        this.appTopbarPresenter.saveConfUserDynamics();
    }

    get f() {
        return this.frmConfigDynamics.controls;
    }
}
