<div class="container">
    <div class="row ml-2">
        <div class="col-3">
            <strong> Usuario: </strong>
        </div>
        <div class="col-9">
            {{user.userName}}
        </div>
    </div>
    <div class="row mt-1">
        <div class="col">
            <form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                <div class="container">
                    <div *ngIf="!isAdmin" class="row">
                        <div class="col">
                            <mat-form-field>
                                <input class="input" matInput placeholder="Contraseña actual" [maxlength]="50"
                                    autocomplete="off" [type]="visibleOldPassword ? 'password' : 'text'"
                                    formControlName="oldPassword">
                                <mat-icon matSuffix (click)="visibleOldPassword = !visibleOldPassword">
                                    {{visibleOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="registerFormControl.oldPassword.value.length === 0">
                                    Campo <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{'spaceError': registerFormControl.password.touched &&
                            registerFormControl.password.errors?.invalidPassword}">
                        <div class="col">
                            <mat-form-field>
                                <input class="input" matInput placeholder="Contraseña nueva" [maxlength]="50"
                                    autocomplete="off" [type]="visiblePassword ? 'password' : 'text'"
                                    formControlName="password">
                                <mat-icon matSuffix (click)="visiblePassword = !visiblePassword">
                                    {{visiblePassword ? 'visibility_off' : 'visibility'}} </mat-icon>
                                <mat-error
                                    *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                                    Campo <strong>requerido.</strong>
                                </mat-error>
                                <mat-error
                                    *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                                    <strong>Al menos 8 caracteres, una letra mayúscula, una
                                        letra minúscula, un número y un carácter especial.</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field>
                                <input class="input" matInput placeholder="Confirmar contraseña" [maxlength]="40"
                                    autocomplete="off" [type]="visibleConfirmPassword ? 'password' : 'text'"
                                    formControlName="confirmPassword">
                                <mat-icon matSuffix (click)="visibleConfirmPassword = !visibleConfirmPassword">
                                    {{visibleConfirmPassword ? 'visibility_off' : 'visibility'}} </mat-icon>
                                <mat-error
                                    *ngIf="(registerFormControl.confirmPassword.touched || submitted)&& registerFormControl.confirmPassword.errors?.required">
                                    Campo <strong>requerido.</strong>
                                </mat-error>
                                <mat-error
                                    *ngIf="registerFormControl.confirmPassword.touched && registerFormControl.confirmPassword.errors?.passwordMismatch">
                                    <strong>Contraseñas no coinciden.</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 right">
                    <div class="col">
                        <button type="submit" pButton pRipple label="Guardar" icon="pi pi-save"
                            class="p-button-info "></button>
                    </div>
                    <div class="col">
                        <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                            class=""></button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<p-confirmDialog *ngIf="!isAdmin" [style]="{width: '450px'}"></p-confirmDialog>
