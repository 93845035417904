import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validatePhone(): ValidatorFn {
    function validation(phone: string): boolean {
        const phoneUtil =
            require('google-libphonenumber').PhoneNumberUtil.getInstance();

        const number1 = phoneUtil.parseAndKeepRawInput(phone, 'EC');

        if (phoneUtil.isValidNumber(number1)) {
            return true;
        } else {
            return false;
        }
    }

    return (control: AbstractControl): ValidationErrors | null => {
        const phone = control.value as string;

        if (!control.value || phone.length <= 2) {
            return null;
        }

        const valid = validation(phone);

        return valid ? null : { valid_phone: { value: control.value } };
    };
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[valid_phone]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PhoneValidatorDirective,
            multi: true,
        },
    ],
})
export class PhoneValidatorDirective implements Validator {
    @Input('valid_phone')
    validate(control: AbstractControl): ValidationErrors | null {
        return validatePhone()(control);
    }
}
