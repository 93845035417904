<div class="container">
    <div class="row">
        <div class="col">
            <form class="form" [formGroup]="systemForm" (ngSubmit)="validateSystem()">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input id="name" placeholder="Nombre" type="text" class="uppercase form-control"
                                formControlName="name" autocomplete="off">
                            <mat-error *ngIf="systemForm.controls.name.errors?.required &&
                                systemForm.get('name').touched">
                                Campo <strong>requerido.</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="code">Código</label>
                            <input id="code" placeholder="Código" type="text" class="uppercase form-control"
                                formControlName="code" autocomplete="off">
                            <mat-error *ngIf="systemForm.controls.code.errors?.required &&
                                systemForm.get('code').touched">
                                Campo <strong>requerido.</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div *ngIf="this.getAuth(['MANAGE_USERS'])" class="col">
                        <div class="d-flex justify-content-center m-2">
                            <mat-checkbox class="check" formControlName="active">
                                Activo
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row right mt-2">
                    <div class="col">
                        <button type="submit" pButton pRipple label="{{isNew?'Guardar':'Actualizar'}}" icon="pi pi-save"
                            class="p-button-info m-r-5"></button>
                    </div>
                    <div class="col">
                        <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                            class=""></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
