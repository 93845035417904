import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Permission, PermissionInput, Rol, RolInput, System, SystemInput } from 'src/app/model.ts/rol.model';

import { GenerateRolView } from './generate-rol.view';
import { GenerateRolPresenter } from './presenter/generate-rol.presenter';

@Component({
    selector: 'app-generate-rol',
    templateUrl: './generate-rol.component.html',
    styleUrls: ['./generate-rol.component.scss'],
})
export class GenerateRolComponent
    extends AbstractView
    implements OnInit, AfterViewInit, GenerateRolView
{
    systems: System[] = [];
    selectedRol: Rol = {};
    selectedSystem: System;
    permissions: Permission[] = [];
    selectedPermissions: Permission[] = [];
    rolForm = this.formBuilder.group({});
    dropdownSettings: IDropdownSettings = {};
    isNew;

    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        protected renderer: Renderer2,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private generateRolPresenter: GenerateRolPresenter
    ) {
        super(messageService, router);
        generateRolPresenter.view = this;
        this.selectedRol = config.data.rol;
        this.isNew = config.data.isNew;
        this.selectedPermissions = this.selectedRol.permissions;
        this.selectedSystem = config.data.system;

        // complete the array
        if (this.selectedPermissions) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.selectedPermissions.length; i++) {
                this.selectedPermissions[i].system = this.selectedSystem;
            }
        }
    }

    ngOnInit(): void {
        this.listSystems();
        this.validateSelectedRol();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchRol();
    }

    validateSelectedRol() {
        if (this.isNew) {
            this.setNewRolToForm();
        } else {
            this.selectedSystem = this.selectedRol.system;
            this.setRolToForm();
        }
        if (this.selectedSystem) {
            this.listPermissionsBySystem();
        }
    }

    setNewRolToForm() {
        this.rolForm = this.formBuilder.group({
            system: new FormControl(this.selectedSystem, [Validators.required]),
            name: new FormControl('', [Validators.required]),
            active: new FormControl(true, [Validators.required]),
            permissions: new FormControl(''),
        });
    }

    setRolToForm() {
        this.rolForm = this.formBuilder.group({
            system: new FormControl(this.selectedRol.system, [
                Validators.required,
            ]),
            name: new FormControl(this.selectedRol.name, [Validators.required]),
            active: new FormControl(this.selectedRol.active, [
                Validators.required,
            ]),
            permissions: new FormControl(''),
        });
    }

    setFocusSearchRol() {
        setTimeout(() => this.renderer.selectRootElement('#name').focus());
    }

    validateRol() {
        this.markFormGroupTouched(this.rolForm);

        if (this.rolForm.invalid) {
            this.showMessage('Información incompleta');
        } else {
            let permissions: PermissionInput[] = [];
            let system: SystemInput;

            this.permissions.forEach((permission) => {
                const found: Permission = this.selectedPermissions.find(
                    (permissionSelected) =>
                        // tslint:disable-next-line
                        permissionSelected.id == permission.id
                );
                if (found) {
                    const systemInput: SystemInput = {
                        id: permission.system.id,
                        name: permission.system.name,
                        code: permission.system.code,
                        active: permission.system.active,
                    };
                    const permissionInput: PermissionInput = {
                        id: permission.id,
                        name: permission.name,
                        domainAction: permission.domainAction,
                        active: permission.active,
                        system: systemInput,
                    };
                    permissions.push(permissionInput);
                }
            });

            permissions = JSON.parse(
                JSON.stringify(permissions),
                this.omitTypename
            );

            system = JSON.parse(
                JSON.stringify(this.rolForm.value.system),
                this.omitTypename
            );

            const rol: RolInput = {
                id: this.selectedRol.id ? this.selectedRol.id : null,
                name: this.rolForm.value.name.toUpperCase(),
                active: this.rolForm.value.active,
                permissions,
                system,
            };
            this.showConfirmation(rol);
        }
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    /* Marks all controls in a form group as touched
       @param formGroup - The form group to touch    */
    private markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    listPermissionsBySystem() {
        this.generateRolPresenter.listPermissionsBySystem();
    }

    listSystems() {
        this.generateRolPresenter.listSystems();
    }

    onItemSelect(per: Permission[]) {
        // const lengthRolesSelected = rolesSelected.length;
        // this.numberOfRoles = lengthRolesSelected;
        this.orderRolesSelected(per);
    }

    orderRolesSelected(selectedPermissions: Permission[]) {
        if (!this.isNew) {
            for (let i = this.permissions.length - 1; i >= 0; i--) {
                // tslint:disable-next-line: prefer-for-of
                for (let j = 0; j < selectedPermissions.length; j++) {
                    if (
                        this.permissions[i] &&
                        this.permissions[i].id === selectedPermissions[j].id
                    ) {
                        this.permissions.splice(i, 1);
                    }
                }
            }
            this.permissions.sort((a, b) => this.Compare(a, b));
            this.selectedPermissions.sort((a, b) => this.Compare(a, b));
            this.permissions = selectedPermissions.concat(this.permissions);
        }
    }

    Compare(a: Permission, b: Permission): number {
        // sort alphabetic
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            // sort string ascending
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0; // default return value (no sorting)
    }

    showConfirmation(rol: RolInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${this.isNew ? 'guardar' : 'actualizar'} el rol?`,
            header: `Confirme ${this.isNew ? 'acción' : 'actualización'}`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.generateRolPresenter.saveRol(rol);
            },
        });
    }

    closeView() {
        this.ref.close();
    }

    compareFn(c1: System, c2: System): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    onSystemChange() {
        this.selectedPermissions = [];
        this.selectedSystem = this.rolForm.get('system').value;
        this.listPermissionsBySystem();
    }
}
