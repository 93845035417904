import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PersonService } from 'src/app/services/person.service';

import { GenerateNewUserView } from '../generate-new-user.view';

@Injectable()
export class GenerateNewUserPresenter implements Presenter {
    view: GenerateNewUserView;

    constructor(public personService: PersonService) {}

    getPersonsBySearch() {
        const searchValue = this.view.value;
        this.view.blockUi();
        this.personService
            .listPersonsByStatus(0, 30, searchValue, true)
            .subscribe(
                ({ data }) => {
                    console.log('data', data);
                    this.view.persons = data.persons.data;
                    if (this.view.persons.length <= 0) {
                        this.view.showWarn('Personas', 'Búsqueda sin registro');
                        this.view.numberResultsPersons = 0;
                    } else {
                        this.view.numberResultsPersons =
                            this.view.persons.length;
                    }
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getPersons() {
        const searchValue = this.view.value;
        this.view.blockUi();
        this.personService
            .listPersonsByStatus(0, 10000, searchValue, true)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.persons = data.persons.data;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen personas cargadas en la base de datos'
                        );
                    }
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }
}
