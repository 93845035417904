import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DomainValidatorDirective } from './directive/domain';
import { NumberAndMiddledashDirective } from './directive/number-middledash';
import { OnlyNumberDirective } from './directive/only-number';
import { OnlyTextDirective } from './directive/only-text';
import { PhoneValidatorDirective } from './directive/phone';
import { TextNumberDirective } from './directive/text-number';
import { TextAndUnderscoreDirective } from './directive/text-undescore ';
import { IdentificationValidatorDirective } from './directive/type-identification';
import { EllipsisPipe } from './pipe/ellipsis.pipe';
import { WordLengthPipe } from './pipe/word-length.pipe';

@NgModule({
    declarations: [
        WordLengthPipe,
        OnlyTextDirective,
        TextNumberDirective,
        TextAndUnderscoreDirective,
        OnlyNumberDirective,
        IdentificationValidatorDirective,
        PhoneValidatorDirective,
        DomainValidatorDirective,
        NumberAndMiddledashDirective,
        EllipsisPipe
    ],
    imports: [CommonModule],
    exports: [
        WordLengthPipe,
        OnlyTextDirective,
        TextNumberDirective,
        TextAndUnderscoreDirective,
        OnlyNumberDirective,
        IdentificationValidatorDirective,
        PhoneValidatorDirective,
        DomainValidatorDirective,
        NumberAndMiddledashDirective,
        EllipsisPipe
    ],
})
export class SharedModule {}
