import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppMainComponent } from './components/main/app.main.component';
import { AuthGuard } from './core/auth.guard';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'main',
                component: AppMainComponent,
                canActivate: [AuthGuard],
            },
            {
                path: '',
                redirectTo: 'main',
                pathMatch: 'full',
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/sign-in/login.module').then(
                        (m) => m.LoginModule
                    ),
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import(
                        './modules/establishment-manage/establishments.module'
                    ).then((m) => m.EstablishmentsManageModule),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import(
                        './modules/configuration-manage/configurationmanage.module'
                    ).then((m) => m.ConfigurationManageModule),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import(
                        './modules/megapos-configuration/megapos-config.module'
                    ).then((m) => m.MegaposConfigModule),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/price-config/price-config.module').then(
                        (m) => m.PriceConfigModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/user-manage/user-manage.module').then(
                        (m) => m.UserManageModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/supplying/supplying.module').then(
                        (m) => m.SupplyingModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/quotations/quotations.module').then(
                        (m) => m.QuotationsModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/reports-mp/reports-mp.module').then(
                        (m) => m.ReportsMpModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/price-pdv/price-pdv.module').then(
                        (m) => m.PricePdvModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () =>
                    import('./modules/product-config/product-config.module').then(
                        (m) => m.ProductConfigModule
                    ),
                canActivate: [AuthGuard],
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'denied', component: AppAccessdeniedComponent },
            { path: '**', component: AppNotfoundComponent },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
