<div class="layout-sidebar" [ngStyle]="{ overflow: app.sidebarActive ? 'hidden' : 'visible' }"
    [ngClass]="{ 'layout-sidebar-dark': app.darkMenu }" (click)="app.onSidebarClick($event)">
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li *ngIf="optionsEstablishments" [ngClass]="{ 'active-item': app.activeTabIndex === 0 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 0)"><i
                        class="pi pi-fw pi-home" pTooltip="Establecimientos"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        ESTABLECIMIENTOS
                    </div>
                </div>
            </li>
            <li *ngIf="optionsPrices" [ngClass]="{ 'active-item': app.activeTabIndex === 1 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 1)"
                    pTooltip="Precios"><span class="material-icons"> monetization_on </span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">PRECIOS</div>
                </div>
            </li>
            <li *ngIf="optionsUsers" [ngClass]="{ 'active-item': app.activeTabIndex === 2 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 2)"
                    pTooltip="Usuarios"><span class="material-icons"> manage_accounts </span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">USUARIOS</div>
                </div>
            </li>

            <li *ngIf="optionsSuggested" [ngClass]="{ 'active-item': app.activeTabIndex === 3 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 3)"
                    pTooltip="Abastecimiento"><span class="material-icons"> how_to_vote </span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        ABASTECIMIENTO
                    </div>
                </div>
            </li>

            <li *ngIf="optionsQuotations" [ngClass]="{ 'active-item': app.activeTabIndex === 4 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 4)"
                    pTooltip="Cotizaciones"><span class="material-icons"> fact_check </span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        ADMINISTRACIÓN
                    </div>
                </div>
            </li>
            <li *ngIf="optionsConfiguration" [ngClass]="{ 'active-item': app.activeTabIndex === 5 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 5)"
                    pTooltip="Configuraciones"><span class='material-icons'>settings</span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        CONFIGURACIONES
                    </div>
                </div>
            </li>
            <li *ngIf="optionsReportsMp" [ngClass]="{ 'active-item': app.activeTabIndex === 6 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 6)"
                    pTooltip="Reportes"><span class='material-icons'>content_paste</span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        REPORTES
                    </div>
                </div>
            </li>
            <li *ngIf="optionsProduct" [ngClass]="{ 'active-item': app.activeTabIndex === 7 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 7)"
                    pTooltip="Productos"><span class="menu-button pi pi-shopping-bag" style="font-size: 1.60rem"></span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        PRODUCTOS
                    </div>
                </div>
            </li>
            <li *ngIf="optionsPricingPdv" [ngClass]="{ 'active-item': app.activeTabIndex === 8 }">
                <a href="#" pRipple class="tabmenuitem-link" (click)="app.onTabClick($event, 8)"
                    pTooltip="Precios PDV"><span class="material-icons"> attach_money </span>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">
                        PRECIOS PDV
                    </div>
                </div>
            </li>
        </ul>
        <div class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{
                            'layout-tabmenu-content-active': app.activeTabIndex === 0
                        }">
                <div class="layout-submenu-title clearfix">
                    <span>ESTABLECIMIENTOS</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-establishment-menu></app-establishment-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                            'layout-tabmenu-content-active': app.activeTabIndex === 1
                        }">
                <div class="layout-submenu-title clearfix">
                    <span>PRECIOS</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-price-menu></app-price-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                            'layout-tabmenu-content-active': app.activeTabIndex === 2
                        }">
                <div class="layout-submenu-title clearfix">
                    <span>USUARIOS</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-user-menu></app-user-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                            'layout-tabmenu-content-active': app.activeTabIndex === 3
                        }">
                <div class="layout-submenu-title clearfix">
                    <span>ABASTECIMIENTO</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-supplying-menu></app-supplying-menu>
                </app-sidebarTabContent>
            </div>

            <div class="layout-tabmenu-content" [ngClass]="{
                        'layout-tabmenu-content-active': app.activeTabIndex === 4
                    }">
                <div class="layout-submenu-title clearfix">
                    <span>ADMINISTRACIÓN</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-quotations-menu></app-quotations-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                        'layout-tabmenu-content-active': app.activeTabIndex === 5
                    }">
                <div class="layout-submenu-title clearfix">
                    <span>CONFIGURACIONES</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-configuration-menu></app-configuration-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                        'layout-tabmenu-content-active': app.activeTabIndex === 6
                    }">
                <div class="layout-submenu-title clearfix">
                    <span>REPORTES</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-report-menu></app-report-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{
                        'layout-tabmenu-content-active': app.activeTabIndex === 7
                    }">

                <div class="layout-submenu-title clearfix">
                    <span>PRODUCTOS</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-product-menu></app-product-menu>
                </app-sidebarTabContent>
            </div>


            <div class="layout-tabmenu-content" [ngClass]="{
                    'layout-tabmenu-content-active': app.activeTabIndex === 8
                }">
                <div class="layout-submenu-title clearfix">
                    <span>PRECIOS PDV</span>
                    <a href="#" style="text-decoration-line: none;" class="menu-button pi pi-chevron-left"
                        (click)="app.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-price-pdv-menu></app-price-pdv-menu>
                </app-sidebarTabContent>
            </div>


        </div>
    </div>
</div>