import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
@Component({
    selector: 'app-quotations-menu',
    templateUrl: './quotations-menu.component.html',
})
export class QuotationsMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit(): void {
        if (this.getAuth(['MANAGE_QUOTES'])) {
            this.model.push({
                label: 'Cotizaciones',
                icon: '',
                routerLink: [`/quotations/manage`],
            });
        }
    }
}
