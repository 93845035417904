import { AbstractView } from 'src/app/core/abstract_view';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector: 'app-product-menu',
    templateUrl: './product-menu.component.html',
})
export class ProductMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }
    ngOnInit() {
            this.model.push({
                label: 'Productos',
                icon: '',
                routerLink: ['/products/manage-products'],
            });
    }
}
