import { UserInput } from './user.model';

export interface UserLogInput {
    id?: string;
    user?: UserInput;
    transactionBy?: string;
    transaction?: string;
    transactionUserLogType?: string;
    updatedAt?: string;
}

export interface UserLog {
    id?: string;
    user?: UserInput;
    transactionBy?: string;
    transaction?: string;
    transactionUserLogType?: string;
    updatedAt?: string;
}

export enum TransactionUserLogType {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    UPDATE = 'UPDATE'
}
