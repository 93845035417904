import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent extends AbstractView implements OnInit {
    public model: any[];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit() {
        if (this.getAuth(['MANAGE_USERS'])) {
            this.model = [
                {
                    label: 'Manejo de Usuarios',
                    icon: 'pi pi-users',
                    routerLink: ['/user/persons'],
                },
            ];
        }
    }
}
