import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import {
    Warehouse,
    WarehouseInput,
} from 'src/app/model.ts/establishment.model';
import {
    IdentificationType,
    Person,
    PersonInput,
    TypeIdentification,
} from 'src/app/model.ts/person.model';
import { Rol, RolInput, System, SystemInput } from 'src/app/model.ts/rol.model';
import { User, UserInput } from 'src/app/model.ts/user.model';
import { CustomValidationService } from 'src/app/services/custom-validation.service';

import { GenerateUserView } from './generate-user.view';
import { GenerateUserPresenter } from './presenter/generate-user.presenter';
import { UserLogInput } from 'src/app/model.ts/user-log.model';

@Component({
    selector: 'app-generate-user',
    templateUrl: './generate-user.component.html',
    styleUrls: ['./generate-user.component.scss'],
})
export class GenerateUserComponent
    extends AbstractView
    implements OnInit, GenerateUserView {
    roles: Rol[] = [];
    rolesSelected: Rol[] = [];
    selectedUser: UserInput;
    selectedPerson: PersonInput;

    selectedSystem: System;

    warehouses: Warehouse[] = [];
    warehouseSelected: WarehouseInput;

    userForm = this.formBuilder.group({});

    showSelectedWarehouse = false;
    visiblePassword = true; // do not delete
    numberOfRoles = 0;
    dropdownSettings: IDropdownSettings = {};

    isNew = false;

    systemName;
    rolName;
    systems: System[] = [];
    treePermissions: TreeNode[];
    mapForm = new Map();

    idUser = '';
    idPerson = '';
    userLog: UserLogInput = {};
    currentUser: any = {};
    logsUpdate = [];
    isDisabledUser = false;
    addRoles = [];
    removeRoles = [];
    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        private messageService: MessageService,
        private customValidator: CustomValidationService,
        private confirmationService: ConfirmationService,
        private generateUserPresenter: GenerateUserPresenter
    ) {
        super(messageService, router);

        generateUserPresenter.view = this;
        this.isNew = config.data.isNew;
        this.currentUser = config.data.currentUser;
        this.userForm = this.formBuilder.group({
            userName: new FormControl('', [Validators.required]),
            fullName: new FormControl('', []),
            password: new FormControl('', [
                Validators.compose([Validators.required]),
            ]),
            phone: new FormControl(''),
            email: new FormControl(''),
            dni: new FormControl('', [Validators.required]),
            pin: new FormControl(''),
            active: new FormControl(true),
        });

        if (!this.isNew) {
            this.idUser = config.data.user;
            this.userById();
        } else {
            this.idPerson = config.data.person;
            this.personById();
        }
    }

    ngOnInit(): void {
        this.listSystems();

        this.listEstablishmetsMegapos();

        this.builMapForm();
    }

    userById() {
        this.generateUserPresenter.userById(this.idUser);
    }
    personById() {
        this.generateUserPresenter.personById(this.idPerson);
    }

    builMapForm() {
        this.mapForm.set('dni', 'Identificación');
        this.mapForm.set('fullName', 'Nombre Completo');
        this.mapForm.set('phone', 'Teléfono');
        this.mapForm.set('email', 'Correo');
    }

    findInvalidControls() {
        const invalid = [];
        const controls = this.userForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(this.mapForm.get(name));
            }
        }
        return invalid;
    }

    listEstablishmetsMegapos() {
        this.generateUserPresenter.listWarehousesByValue();
    }

    listSystems() {
        this.generateUserPresenter.listSystems();
    }

    setRolesBySystem(system: System) {
        this.systemName = system.name;
        this.setClassToSystem(system);
        this.selectedSystem = system;
        this.generateUserPresenter.listRolesBySystemId();
    }

    setClassToSystem(item: System) {
        this.systems.forEach((e) => {
            $(`#${e.id}`).removeClass('active');
        });

        this.systems.forEach((e) => {
            $(`#w-${e.id}`).removeClass('show');
            $(`#w-${e.id}`).removeClass('active');
        });

        $(`#w-${item.id}`).addClass('show');
        $(`#w-${item.id}`).addClass('active');
    }

    validateSelectedUser() {
        if (this.isNew) {
            // megapos
            this.warehouseSelected = null;

            this.setNewPersonToForm();
        } else {
            this.setUserToForm();
        }
        this.setEstablishmentMegapos();
    }

    setNewPersonToForm() {
        this.userForm = this.formBuilder.group({
            userName: new FormControl(this.selectedPerson.userName, [
                Validators.required,
            ]),
            fullName: new FormControl(this.selectedPerson.fullName),
            password: new FormControl('', [
                Validators.compose([
                    Validators.required,
                    this.customValidator.patternValidator(),
                ]),
            ]),
            phone: new FormControl(this.selectedPerson.phone),
            identificationType: new FormControl(
                this.setIdentificationType(
                    this.selectedPerson.identificationType
                )
            ),
            email: new FormControl(this.selectedPerson.email),
            dni: new FormControl(this.selectedPerson.dni, [
                Validators.required,
            ]),
            /* pin: new FormControl(this.selectedPerson.pin), */
            active: new FormControl(true),
        });
    }

    setIdentificationType(identificationType: string) {
        const listIdentifications = Object.keys(IdentificationType).map(
            (key) => ({ name: IdentificationType[key], id: key })
        );

        const typeIdentification = listIdentifications.find(
            (item: TypeIdentification) => item.id === identificationType
        );
        return typeIdentification.name;
    }

    setUserToForm() {
        this.userForm = this.formBuilder.group({
            userName: new FormControl(this.selectedUser.userName, [
                Validators.required,
            ]),
            fullName: new FormControl(this.selectedUser.person.fullName),
            password: new FormControl('*****'),
            phone: new FormControl(this.selectedUser.person.phone),
            email: new FormControl(this.selectedUser.person.email),
            dni: new FormControl(this.selectedUser.person.dni),
            pin: new FormControl(this.selectedUser.pin),
            active: new FormControl(this.selectedUser.active),
        });
    }

    closeView() {
        this.ref.close();
    }

    showConfirmation(user: UserInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${this.isNew ? 'guardar' : 'actualizar'
                } el usuario?`,
            header: `Confirme ${this.isNew ? 'acción' : 'actualización'}`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.saveUser(user);
            },
        });
    }

    setEstablishmentMegapos() {
        this.setDropdownSettings();

        this.warehouseSelected = this.selectedUser?.warehouse
            ? this.selectedUser.warehouse
            : null;
        this.showSelectedWarehouse = this.selectedUser?.warehouse
            ? true
            : false;
    }

    setDropdownSettings() {
        this.dropdownSettings = {
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            searchPlaceholderText: 'Buscar..',
            enableCheckAll: false,
            itemsShowLimit: 1,
        };
    }
    onWarehouseSelect(warehouse: Warehouse) {
        this.showSelectedWarehouse = true;
        this.warehouseSelected = warehouse;
    }

    setListPermissionByRoles(rolesSelected: Rol[]) {
        this.setPermissionsToTree();

        /* const lengthRolesSelected = rolesSelected.length;
        this.numberOfRoles = lengthRolesSelected;
        */

        // this.orderRolesSelected(rolesSelected);
    }

    clearEstablishment() {
        this.showSelectedWarehouse = false;
        this.warehouseSelected = null;
    }

    setClassToSystemInit() {
        const sys: System = this.systems[0];
        $(`#${sys.id}`).addClass('active');
        $(`#w-${sys.id}`).addClass('show');
        $(`#w-${sys.id}`).addClass('active');
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    setWarehouseMegaposToSave(): WarehouseInput {
        let warehouse: WarehouseInput;

        if (this.warehouseSelected) {
            warehouse = this.warehouses.find(
                (e) => e.id === this.warehouseSelected.id
            );
            warehouse = JSON.parse(
                JSON.stringify(warehouse),
                this.omitTypename
            );
        }

        return warehouse;
    }

    setPermissionsToTree() {
        const sheets: Sheet[] = [];
        for (const sys of this.rolesSelected) {
            const sheet = new Sheet(sys.system);
            sheets.push(sheet);
        }

        const distinctSheets: Sheet[] = sheets.filter(
            (sheet, index, self) =>
                index === self.findIndex((t) => t.system.id === sheet.system.id)
        );

        const arbol: Sheet[] = [];
        distinctSheets.forEach((sheet) => {
            const hoja = new Sheet(sheet.system);

            const roles = this.rolesSelected.filter(
                (rol) => rol.system.id === sheet.system.id
            );

            hoja.roles = roles;

            arbol.push(hoja);
        });
        this.converToLstData(arbol);
    }

    converToLstData(arbol: Sheet[]) {
        const lstData: Data[] = [];
        for (const tree of arbol) {
            const dataSystem = new Data();
            dataSystem.label = tree.system.name;
            dataSystem.icon = null;
            for (const rol of tree.roles) {
                const childrenRol = new Data();
                childrenRol.label = rol.name;
                const dataRol = new Data();
                dataRol.label = rol.name;
                dataRol.icon = null;
                for (const permission of rol.permissions) {
                    const childrenPermission = new Data();
                    childrenPermission.label = permission.name;
                    childrenPermission.collapsedIcon = null;
                    childrenPermission.expandedIcon = null;

                    dataRol.children.push(childrenPermission);
                }
                dataSystem.children.push(dataRol);
            }
            lstData.push(dataSystem);
        }

        this.treePermissions = lstData as TreeNode[];
    }

    orderRolesSelected(rolesSelected: Rol[]) {
        for (let i = this.roles.length - 1; i >= 0; i--) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < rolesSelected.length; j++) {
                if (this.roles[i] && this.roles[i].id === rolesSelected[j].id) {
                    this.roles.splice(i, 1);
                }
            }
        }
        this.roles.sort((a, b) => this.Compare(a, b));
        this.rolesSelected.sort((a, b) => this.Compare(a, b));
        this.roles = rolesSelected.concat(this.roles);
    }

    validateUser() {
        if (this.userForm.invalid) {
            this.showMessage(
                'Información incompleta en ' + this.findInvalidControls()
            );
            return;
        }

        this.setUserToSave();
    }
    setUserToSave() {
        this.addRoles = [];
        this.removeRoles = [];

        const roles: RolInput[] = this.setRolesBySystemToSave();
        const warehouse: WarehouseInput = this.setWarehouseMegaposToSave();
        const person: PersonInput = this.setPersonToSave();
        /* let establishmentsUser: WarehouseInput[] = []; */
        /* establishmentsUser = JSON.parse(
            JSON.stringify(this.establishmentsPuntosDeVenta),
            this.omitTypename
        ); */

        const user: UserInput = {
            id: this.selectedUser?.id ? this.selectedUser.id : null,
            userName: this.userForm.value.userName,
            password: this.selectedUser?.id
                ? '*****'
                : this.userForm.value.password,
            warehouse: warehouse || null,
            pin: this.userForm.value.pin,
            active: this.userForm.value.active,
            person,
            roles: roles || [],
            /* warehouses: establishmentsUser || [], */
        };

        const difference: any = this.getDifference(this.selectedUser, user);

        this.addRoles = user.roles.filter(obj1 => !this.selectedUser?.roles.some(obj2 =>
            obj1.id === obj2.id && obj1.name === obj2.name));
        this.removeRoles = this.selectedUser?.roles.filter(obj1 =>
            !user.roles.some(obj2 => obj1.id === obj2.id && obj1.name === obj2.name));

        if (this.selectedUser == null || undefined) {
            this.logsUpdate.push('USUARIO importado');
        }

        if (difference.pin) {
            this.logsUpdate.push('PIN actualizado');
        }
        if (this.addRoles?.length > 0 || this.removeRoles?.length > 0) {
            const selectedAddRoles = [];
            const selectedRemoveRoles = [];
            this.addRoles.forEach((role) => {
                selectedAddRoles.push(role.name);
            });
            this.removeRoles.forEach((role) => {
                selectedRemoveRoles.push(role.name);
            });
            this.logsUpdate.push(`ROLES actualizado, ${this.addRoles.length > 0 ? 'agregados: ' + selectedAddRoles.toString() : ''} ${this.removeRoles.length > 0 ? 'removidos: ' + selectedRemoveRoles.toString() : ''}`);
        }
        if (difference.userName) {
            this.logsUpdate.push('USERNAME actualizado');
        }
        if (difference.warehouse !== undefined) {
            if (difference?.warehouse?.id !== this.selectedUser?.warehouse?.id) {
                this.logsUpdate.push('ALMACÉN actualizado');
            }
        }
        if (difference.active !== undefined) {
            this.isDisabledUser = true;
        }
        this.showConfirmation(user);
    }

    setPersonToSave(): PersonInput {
        const person: PersonInput = {
            id: this.selectedPerson.id,
            active: this.selectedPerson.active,
            dni: this.selectedPerson.dni,
            email: this.selectedPerson.email,
            fullName: this.selectedPerson.fullName,
            identificationType: this.selectedPerson.identificationType,
        };
        this.selectedPerson = JSON.parse(
            JSON.stringify(this.selectedPerson),
            this.omitTypename
        );

        return person;
    }

    saveUser(user: UserInput) {
        if (!user.person.active) {
            this.showWarn(
                'Advertencia',
                'Active la persona, luego puede guardar el usuario'
            );
            return;
        }

        if (this.isNew) {
            user.id = null;
        }

        this.generateUserPresenter.saveUser(user);
    }

    setRolesBySystemToSave(): RolInput[] {
        let roles: RolInput[] = [];

        if (this.rolesSelected.length > 0) {
            this.rolesSelected.forEach((rol) => {
                const system: SystemInput = {
                    id: rol.system.id,
                    name: rol.system.name,
                    code: rol.system.code,
                    active: rol.system.active,
                };
                const rolInput: RolInput = {
                    id: rol.id,
                    name: rol.name,
                    active: rol.active,
                    system,
                };
                roles.push(rolInput);
            });

            roles = JSON.parse(JSON.stringify(roles), this.omitTypename);
        }

        return roles;
    }

    setSystemToRoles() {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.roles.length; i++) {
            this.roles[i].system = this.selectedSystem;
        }
    }

    setRolesSelectedBySystem() {
        // this.rolesSelected = [];
        let position = 0;
        for (const rol of this.roles) {
            for (const rolSelect of this.rolesSelected) {
                if (rol.id === rolSelect.id) {
                    this.rolesSelected[position] = rol;
                    this.rolesSelected[position].system = rolSelect.system;
                    this.rolesSelected[position].permissions =
                        rolSelect.permissions;
                    position++;
                }
            }
        }
        this.setPermissionsToTree();
    }

    Compare(a, b): number {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    currentPermissionsByRol(rol: Rol) {
        let permissions = '';
        rol.permissions.forEach((r) => {
            permissions = permissions + '- ' + r.name + '\n';
        });

        if (permissions === '') {
            permissions = 'Sin permisos asignados';
        }

        return permissions;
    }

    getDifference(obj1, obj2) {
        const difference = {};
        for (const key in obj1) {
            if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
                difference[key] = obj2[key];
            }
        }
        return difference;
    }
}

class Sheet {
    public constructor(system: System) {
        this.system = system;
    }
    system?: System;
    roles?: Rol[];
}
class Data {
    constructor() {
        this.expandedIcon = 'pi pi-folder-open';
        this.collapsedIcon = 'pi pi-folder';
        this.icon = 'pi pi-unlock';
        this.leaf = true;
    }
    label: string;
    expandedIcon?: string;
    collapsedIcon?: string;
    icon?: string;
    children?: TreeNode[] = [];
    leaf: boolean;
    parent?: TreeNode;
}
