<div class="exception-body pagenotfound-body">
    <div class="exception-panel">
        <img src="assets/layout/images/exception/icon-404.png" />

        <p-button label="IR A LA PÁGINA PRINCIPAL" [routerLink]="['/']"></p-button>
    </div>

    <div class="exception-band">
        <div class="exception-content">
            <h3>PÁGINA NO ENCONTRADA</h3>
            <p>La página que solicitas no puede ser encontrada</p>
            <img src="assets/layout/images/login/logo_login.png" />
        </div>
    </div>
</div>
