import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth.guard';

import { PermissionsComponent } from './components/permisions/permissions.component';
import { PersonsComponent } from './components/persons/persons.component';
import { RolesComponent } from './components/roles/roles.component';
import { SystemsComponent } from './components/systems/systems.component';
import { UserManageComponent } from './user-manage.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
    {
        path: 'user',
        component: UserManageComponent,
        children: [
            {
                path: 'persons',
                component: PersonsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'systems',
                component: SystemsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'roles',
                component: RolesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'permisions',
                component: PermissionsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserManageRoutingModule {}
