import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { SystemInput } from 'src/app/model.ts/rol.model';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';

import { SystemsView } from '../systems.view';

@Injectable()
export class SystemsPresenter implements Presenter {
    view: SystemsView;

    constructor(
        private systemsRolesPermissionsService: SystemsRolesPermissionsService
    ) {}

    listSystems(active: boolean) {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                active
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.systems = data.systems.data;
                        this.view.totalElements = data.systems.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberSystems() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(0, 1000, '', !this.view.activeSystem)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberSystems = data.systems.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    saveSystem(system: SystemInput) {
        this.view.blockUi();
        this.systemsRolesPermissionsService.saveSystem(system).subscribe(
            ({ data }) => {
                this.view.showSuccess('Sistema', 'Guardado con éxito');
                this.view.listSystems();
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    systemById(systemId: string) {
        this.view.blockUi();
        this.systemsRolesPermissionsService.systemById(systemId).subscribe(
            ({ data }) => {
                this.view.selectedSystem = data.findSystemById;
                this.view.showGenerateSystemComponent(
                    'Editar sistema ' + data.findSystemById.name,
                    false
                );
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
