import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PersonInput } from 'src/app/model.ts/person.model';
import { PersonService } from 'src/app/services/person.service';

import { GeneratePersonView } from '../generate-person.view';

@Injectable()
export class GeneratePersonPresenter implements Presenter {
    view: GeneratePersonView;

    constructor(private personService: PersonService) {}

    savePerson(person: PersonInput) {
        this.view.blockUi();
        this.personService.savePerson(person).subscribe(
            () => {
                this.view.showSuccess('Persona', 'Actualizado con éxito');
                this.view.closeView();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    listPersons(active: boolean) {
        const searchValue = this.view.value;
        this.view.blockUi();
        this.personService
            .listPersonsByStatus(0, 30, searchValue, active)
            .subscribe(
                ({ data }) => {
                    this.view.persons = data.persons.data;
                    if (this.view.persons.length <= 0) {
                        this.view.showWarn('Personas', 'Búsqueda sin registro');
                        this.view.numberResultsPersons = 0;
                    } else {
                        this.view.numberResultsPersons =
                            this.view.persons.length;
                    }
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    personById(id) {
        this.view.blockUi();
        this.personService.personById(id).subscribe(
            ({ data }) => {
                this.view.selectedPerson = data.personById;
                this.view.selectedPerson.dni =
                    this.view.selectedPerson.dni.trim();
                this.view.buildIdentificationTypeArray();
                this.view.setPersonToForm();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
