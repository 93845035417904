<div class="login-body">
    <div class="login-panel p-fluid">
        <div class="p-grid p-nogutter">
            <div class="p-col-12 login-header">
                <h1>LOGIN</h1>
                <h2>Welcome to Barcelona</h2>
                <img src="assets/layout/images/logo.png">
            </div>
            <div class="p-col-12">
                <span class="p-float-label">
                    <input type="text" pInputText>
                    <label>Username</label>
                </span>
            </div>
            <div class="p-col-12">
                <span class="p-float-label">
                    <input type="text" pInputText>
                    <label>Password</label>
                </span>
            </div>
            <div class="p-col-12 button-container">
                <button pButton type="button" label="Sign In" icon="pi pi-user"></button>
            </div>
        </div>
    </div>
</div>
