<div class="row">
    <div class="col">
        <form class="form" [formGroup]="personForm" (ngSubmit)="validatePerson()">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="type">Tipo de identificación</label>
                        <select [compareWith]="compareFn" id="type" formControlName="type" class="form-control">
                            <option [ngValue]="null" disabled>Ingrese identificación</option>
                            <option *ngFor="let type of listIdentifications" [ngValue]="type">{{type.name}}
                            </option>
                        </select>
                        <mat-error *ngIf="personForm.controls.type.errors?.required">
                            Tipo de identificación <strong>no válida.</strong>
                        </mat-error>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="dni">Identificación</label>
                        <input id="dni" type="text" maxlength="20" autocomplete="off"
                            (input)="onSearchChangeDNI($event.target.value)"
                            oninput="this.value=this.value.slice(0,this.maxLength)" class="form-control" minlength="4"
                            formControlName="dni">
                        <mat-error *ngIf="personForm.controls.dni.errors?.required">
                            Campo <strong>requerido.</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls.dni.errors?.minlength">
                            Debe tener <strong>mínimo 4 caracteres.</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="fullName">Nombre Completo</label>
                        <input id="fullName" type="text" maxlength="100" autocomplete="off"
                            oninput="this.value=this.value.slice(0,this.maxLength)" class="uppercase form-control"
                            formControlName="fullName">
                        <mat-error *ngIf="personForm.controls.fullName.errors?.required">
                            Campo <strong>requerido.</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="userName">Usuario LDAP</label>
                        <input id="userName" readonly type="text" class="form-control" formControlName="userName">
                        <mat-error *ngIf="personForm.controls.userName.errors?.required">
                            Campo <strong>requerido.</strong>
                        </mat-error>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="phone">Teléfono</label>
                        <input id="phone" type="text" maxlength="11" required minlength="8" appInputNumberAndMiddledash
                            autocomplete="off" oninput="this.value=this.value.slice(0,this.maxLength)"
                            class="form-control" formControlName="phone">
                        <mat-error *ngIf="personForm.controls.phone.errors?.valid_phone">
                            Teléfono <strong>incorrecto.</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls.phone.errors?.required">
                            Campo <strong>requerido.</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="email">Correo</label>
                        <input id="email" type="text" maxlength="100" autocomplete="off"
                            oninput="this.value=this.value.slice(0,this.maxLength)" class="form-control"
                            formControlName="email">
                        <mat-error *ngIf="personForm.controls.email.errors?.required">
                            Campo <strong>requerido.</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls.email.errors?.pattern">
                            Correo <strong>no válido.</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls.email.errors?.valid_domain">
                            Dominio <strong>no válido.</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div *ngIf="this.getAuth(['MANAGE_USERS'])" class="col">
                    <div class="d-flex justify-content-center m-2">
                        <mat-checkbox class="check" formControlName="active">
                            Activo
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row right">
                <div class="col">
                    <button type="submit" pButton pRipple label="{{isImport?'Importar':'Actualizar'}}"
                        [icon]="isImport? 'pi pi-upload' : 'pi pi-save'" class="p-button-info "></button>
                </div>
                <div class="col">
                    <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                        class=""></button>
                </div>
            </div>
        </form>
    </div>
</div>
