<p-toast></p-toast>

<div class="continer-card">
    <div class="card card-botonera">
        <div class="row justify-content-between">
            <div class="pl-3">
                <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button bc-primary"
                    (click)="openGenerateRolComponent()"></button>
            </div>
            <div class="row">
                <div class="pr-3 pt-2">
                    <h5>Ver {{numberRoles}} roles {{activeRoles ?'Inactivos':'Activos'}}</h5>
                </div>
                <div class="pr-3 pt-2">
                    <p-inputSwitch [(ngModel)]="activeRoles" (onChange)="clickActiveRolChecked()">
                    </p-inputSwitch>
                </div>
            </div>
            <div class="pr-4">
                <p-dropdown class="pr-3" [options]="systems" [(ngModel)]="selectedSystem"
                    placeholder="Seleccione Sistema" optionLabel="name" (ngModelChange)="listRoles()">
                </p-dropdown> <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input id="searchRol" maxlength="20" style="height: 35px;" autofocus class="size-200"
                        autoHighlight="true" type="text" pInputText appInputOnlyText (keyup)="onSearchChange($event)"
                        [minLength]="3" placeholder="Buscar rol..">
                </span>
            </div>
        </div>
    </div>

    <p-table [value]="roles" styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines">
        <ng-template class="around" pTemplate="header">
            <tr>
                <th id="col-rol">Rol </th>
                <th id="col-system">Sistema </th>
                <th id="col-authorization">Autorización </th>
                <th id="col-status">Estado</th>
                <th id="col-action">Acción</th>
            </tr>
        </ng-template>
        <ng-template class="around" pTemplate="body" let-rol>
            <tr>
                <td>{{rol.name}}</td>
                <td>{{rol.system?.name}}</td>
                <td style="text-align: center;" pTooltip="{{currentPermissionsByRol(rol)}}">
                    {{rol.permissions.length }} permisos
                    asignados</td>
                <td>
                    <div class="row">
                        <div class="col">
                            <div class="d-flex justify-content-center">
                                <p-checkbox pTooltip="Inactivar Rol" [style]="{cursor: 'pointer'}"
                                    (click)="changeRolActive(rol)" [(ngModel)]="rol.active" binary="false">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </td>
                <td style="text-align: center;" class="around">
                    <i class="material-icons color-icon-primary" type="button" pTooltip="Editar" tooltipPosition="left"
                        (click)="editGenerateRolComponent(rol)">drive_file_rename_outline
                    </i>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Existen {{totalElements ? totalElements : 0 }} roles {{activeRoles ?'Activos':'Inactivos'}}
            </div>
        </ng-template>
    </p-table>
    <h2 class="center m-4 text-warning" *ngIf="totalElements==0">No hay resultados de su busqueda</h2>
    <p-paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)"></p-paginator>

</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
