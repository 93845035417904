import { validateCedula } from './cedula';

export function validateRuc(ruc: string): boolean {
    const noTieneTreceDigitos = ruc.length !== 13;
    if (noTieneTreceDigitos) {
        return false;
    }
    const noTieneCeroCeroUnoAlFinal = !(ruc.substring(10) === '001');
    if (noTieneCeroCeroUnoAlFinal) {
        return false;
    }

    return validarDigitoVerificador(ruc);
}

function validarDigitoVerificador(ruc: string): boolean {
    const diezDigitosIniciales = ruc.substring(0, 10);

    return validateCedula(diezDigitosIniciales);
}
