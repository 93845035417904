import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { System, SystemInput } from 'src/app/model.ts/rol.model';

import { GenerateSystemComponent } from '../generate-system/generate-system.component';
import { SystemsPresenter } from './presenter/systems.presenter';
import { SystemsView } from './systems.view';

@Component({
    selector: 'app-systems',
    templateUrl: './systems.component.html',
    styleUrls: ['./systems.component.scss'],
})
export class SystemsComponent
    extends AbstractView
    implements OnInit, AfterViewInit, SystemsView
{
    systems: System[] = [];
    selectedSystem: System;
    page = 0;
    totalElements = 0;
    size = 12;
    numberSystems = 0;
    value = '';
    activeSystem = true;
    public ref;

    constructor(
        protected renderer: Renderer2,
        public formBuilder: FormBuilder,
        private cdRef: ChangeDetectorRef,
        public dialogService: DialogService,
        private messageService: MessageService,
        private systemsPresenter: SystemsPresenter,
        private confirmationService: ConfirmationService
    ) {
        super(messageService);
        systemsPresenter.view = this;

        if (!this.getAuth(['MANAGE_USERS'])) {
            this.showError('', 'Sin autorización');
            this.redirectTo('main');
        }
    }

    ngOnInit(): void {
        this.listSystems();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchSystem();
    }

    setFocusSearchSystem() {
        setTimeout(() =>
            this.renderer.selectRootElement('#searchSystem').focus()
        );
    }

    listSystems() {
        this.verifyStatusSystem();
    }

    verifyStatusSystem() {
        if (this.activeSystem) {
            this.listActiveSystems();
        } else {
            this.listInactiveSystems();
        }
        this.systemsPresenter.getNumberSystems();
    }

    listActiveSystems() {
        this.systemsPresenter.listSystems(true);
    }

    listInactiveSystems() {
        this.systemsPresenter.listSystems(false);
    }

    openGenerateSystemComponent() {
        this.selectedSystem = {};
        this.showGenerateSystemComponent('Nuevo sistema', true);
    }

    editGenerateSystemComponent(system: System) {
        this.systemsPresenter.systemById(system.id);
    }

    changeSystemActive(system: System) {
        this.selectedSystem = system;

        this.showInactivation();
    }

    showInactivation() {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${
                this.activeSystem ? 'inactivar' : 'activar'
            } el sistema?`,
            header: `Confirme ${
                this.activeSystem ? 'inactivación' : 'activación'
            }`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.setActiveSystemInput();
            },
            reject: () => {
                this.changeActiveSystem();
            },
        });
    }

    changeActiveSystem() {
        this.selectedSystem.active = this.selectedSystem.active ? false : true;
    }

    setActiveSystemInput() {
        const systemInput: SystemInput = {
            id: this.selectedSystem.id,
            name: this.selectedSystem.name.toUpperCase(),
            code: this.selectedSystem.code.toUpperCase(),
            active: this.selectedSystem.active,
        };

        this.systemsPresenter.saveSystem(systemInput);
    }

    clickActiveSystemChecked() {
        this.listSystems();
    }

    onSearchChange(searchValue: any): void {
        const searchLength = searchValue.target.value.length;
        // if (searchValue.keyCode === 13) {
        if (searchLength >= 3 || searchLength === 0) {
            this.value = searchValue.target.value;
            this.value = this.value.toUpperCase();
            this.listSystems();
        }
        // }
    }
    paginate($event) {
        this.page = $event['page'];
        this.listSystems();
    }

    showGenerateSystemComponent(title: string, isNew: boolean) {
        this.ref = this.dialogService.open(GenerateSystemComponent, {
            header: title,
            closeOnEscape: true,
            width: '35%',
            height: 'auto',
            data: { system: this.selectedSystem, isNew },
        });
        this.ref.onClose.subscribe(() => {
            this.listSystems();
        });
    }

}
