import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { DialogService } from 'primeng/dynamicdialog';

import { PrimeModule } from '../prime/prime.module';
import { SharedModule } from '../shared/shared.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordPresenter } from './components/change-password/presenter/change-password.presenter';
import { GenerateImportPersonComponent } from './components/generate-import-person/generate-import-person.component';
import {
  GenerateImportPersonPresenter,
} from './components/generate-import-person/presenter/generate-import-person.presenter';
import { GenerateNewUserComponent } from './components/generate-new-user/generate-new-user.component';
import { GenerateNewUserPresenter } from './components/generate-new-user/presenter/generate-new-user.presenter';
import { GeneratePermissionComponent } from './components/generate-permission/generate-permission.component';
import { GeneratePermissionPresenter } from './components/generate-permission/presenter/generate-permission.presenter';
import { GeneratePersonComponent } from './components/generate-person/generate-person.component';
import { GeneratePersonPresenter } from './components/generate-person/presenter/generate-person.presenter';
import { GenerateRolComponent } from './components/generate-rol/generate-rol.component';
import { GenerateRolPresenter } from './components/generate-rol/presenter/generate-rol.presenter';
import { GenerateSystemComponent } from './components/generate-system/generate-system.component';
import { GenerateSystemPresenter } from './components/generate-system/presenter/generate-system.presenter';
import { GenerateUserComponent } from './components/generate-user/generate-user.component';
import { GenerateUserPresenter } from './components/generate-user/presenter/generate-user.presenter';
import { PermissionsComponent } from './components/permisions/permissions.component';
import { PermissionsPresenter } from './components/permisions/presenter/permissions.presenter';
import { PersonsComponent } from './components/persons/persons.component';
import { PersonsPresenter } from './components/persons/presenter/persons.presenter';
import { RolesPresenter } from './components/roles/presenter/roles.presenter';
import { RolesComponent } from './components/roles/roles.component';
import { SystemsPresenter } from './components/systems/presenter/systems.presenter';
import { SystemsComponent } from './components/systems/systems.component';
import { UserManageComponent } from './user-manage.component';
import { UsersPresenter } from './components/users/presenter/users.presenter';
import { UsersComponent } from './components/users/users.component';
import { UserManageRoutingModule } from './user-manage-routing.module';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { UserLogsPresenter } from './user-logs/presenter/user-logs.presenter';

@NgModule({
    declarations: [
        UserManageComponent,
        UsersComponent,
        RolesComponent,
        PermissionsComponent,
        GeneratePermissionComponent,
        GenerateRolComponent,
        GenerateUserComponent,
        ChangePasswordComponent,
        PersonsComponent,
        GeneratePersonComponent,
        SystemsComponent,
        GenerateSystemComponent,
        GenerateNewUserComponent,
        GenerateImportPersonComponent,
        UserLogsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        UserManageRoutingModule,
        MatTabsModule,
        PrimeModule,
        FormsModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        BlockUIModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatCardModule,
    ],
    exports: [ChangePasswordComponent],
    providers: [
        UsersPresenter,
        ConfirmationService,
        DialogService,
        PermissionsPresenter,
        RolesPresenter,
        GeneratePermissionPresenter,
        GenerateRolPresenter,
        GenerateUserPresenter,
        ChangePasswordPresenter,
        PersonsPresenter,
        GeneratePersonPresenter,
        SystemsPresenter,
        GenerateSystemPresenter,
        GenerateNewUserPresenter,
        GenerateImportPersonPresenter,
        UserLogsPresenter
    ],
    entryComponents: [
        ChangePasswordComponent,
        GeneratePersonComponent,
        GenerateUserComponent,
        GenerateSystemComponent,
        GeneratePermissionComponent,
        GenerateRolComponent,
        GenerateNewUserComponent,
        GenerateImportPersonComponent,
        UserLogsComponent
    ],
})
export class UserManageModule {}
