import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

import { PersonInput } from '../model.ts/person.model';

@Injectable({
    providedIn: 'root',
})
export class PersonService {
    LIST_PERSON_BY_VALUE = gql`
        query findPersonsByValue($value: String!) {
            findPersonsByValue(value: $value) {
                fullName
                dni
                email
                userName
                active
                phone
            }
        }
    `;

    LIST_PERSONS = gql`
        query persons(
            $page: Int!
            $size: Int!
            $value: String!
            $active: Boolean!
        ) {
            persons(page: $page, size: $size, value: $value, active: $active) {
                page
                size
                data {
                    id
                    fullName
                    dni
                    email
                    active
                    referenceId
                    phone
                    identificationType
                    user {
                        id
                        userName
                        active
                    }
                    userName
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                }
            }
        }
    `;

    SAVE_PERSON = gql`
        mutation savePerson($person: PersonInput) {
            savePerson(person: $person) {
                id
                fullName
                dni
                email
                active
                referenceId
                phone
                identificationType
                userName
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    `;

    PERSON_BY_ID = gql`
        query personById($id: String) {
            personById(id: $id) {
                id
                fullName
                dni
                email
                active
                referenceId
                phone
                identificationType
                userName
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    `;

    PERSONS_COUNT_STATUS = gql`
        query personsStatusCount($status: Boolean!) {
            personsStatusCount(status: $status)
        }
    `;

    constructor(private apollo: Apollo) {}

    isUpdateListPersons = false;
    isUpdateListUsers = false;

    omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    omitType(model) {
        return JSON.parse(JSON.stringify(model), this.omitTypename);
    }

    listPersonsByStatus(
        page: number,
        size: number,
        value: string,
        active: boolean
    ) {
        return this.apollo.query<any>({
            query: this.LIST_PERSONS,
            variables: { page, size, value, active },
        });
    }

    listPersonsByValueOfLDAP(value: string) {
        return this.apollo.query<any>({
            query: this.LIST_PERSON_BY_VALUE,
            variables: { value },
        });
    }

    savePerson(person: PersonInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_PERSON,
            variables: { person },
        });
    }

    personById(id: string) {
        return this.apollo.query<any>({
            query: this.PERSON_BY_ID,
            variables: { id },
        });
    }

    personsStatusCount(status: boolean) {
        return this.apollo.query<any>({
            query: this.PERSONS_COUNT_STATUS,
            variables: { status },
        });
    }
}
