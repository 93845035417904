import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
    selector: 'app-user-manage',
    templateUrl: './user-manage.component.html',
    styleUrls: ['./user-manage.component.scss'],
})
export class UserManageComponent extends AbstractView implements OnInit {
    constructor(private messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    navLinks = [];

    ngOnInit() {
        this.navLinks = [
            {
                label: 'Personas',
                link: './persons',
                index: 0,
                visible: this.getAuth([
                    'MANAGE_USERS'
                ]),
                icon: 'pi pi-users',
            },
            {
                label: 'Usuarios',
                link: './users',
                index: 0,
                visible: this.getAuth([
                    'MANAGE_USERS'
                ]),
                icon: 'pi pi-user-edit',
            },
            {
                label: 'Sistemas',
                link: './systems',
                index: 0,
                visible: this.getAuth(['MANAGE_USERS']),
                icon: 'pi pi-slack',
            },
            {
                label: 'Permisos',
                link: './permisions',
                index: 0,
                visible: this.getAuth(['MANAGE_USERS']),
                icon: 'pi pi-ban',
            },
            {
                label: 'Roles',
                link: './roles',
                index: 0,
                visible: this.getAuth(['MANAGE_USERS']),
                icon: 'pi pi-sitemap',
            },
        ];
    }
}
