import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordLength'
})
export class WordLengthPipe implements PipeTransform {

  transform(value: string[], args?: any): any {

    let newString = value;

    if (value.length >= 28) {
      const formatString = value.slice(0, 20);
      newString = formatString.concat('...');
    }

    return newString;
  }

}
