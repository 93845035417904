export interface ConfigUserDynamic {
    id?: string;
    grantType?: string;
    clientId?: string;
    clientSecret?: string;
    resource?: string;
    type?: ConfigurationType;
}
export interface ConfigUserDynamicInput {
    id?: string;
    grantType?: string;
    clientId?: string;
    clientSecret?: string;
    resource?: string;
    type?: ConfigurationType;
}

export enum ConfigurationType {
    DYNAMICS = 'DYNAMICS',
}
