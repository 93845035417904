<div class="exception-body error-body">
    <div class="exception-panel">
        <img src="assets/layout/images/exception/icon-error.png">

        <p-button label="IR A LA PÁGINA PRINCIPAL" [routerLink]="['/main']"></p-button>
    </div>

    <div class="exception-band">
        <div class="exception-content">
            <h3>Error de conexión :(</h3>
            <p>Por favor intente otra vez, más tarde</p>
            <img src="assets/layout/images/login/logo_login.png" />
        </div>
    </div>
</div>
