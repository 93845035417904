
import { Directive, Input } from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn
} from '@angular/forms';

export function validateDomain(): ValidatorFn {
    function validation(email: string): boolean {

        const isValidDomain = require('is-valid-domain');
        const validator = require('validator');

        const domain = email.split('@').pop();

        if (isValidDomain(domain) && validator.isEmail(email)) {
            return true;
        } else {
            return false;
        }
    }

    return (control: AbstractControl): ValidationErrors | null => {
        const domain = control.value as string;

        if (!control.value) {
            return null;
        }

        const valid = validation(domain);

        return valid ? null : { valid_domain: { value: control.value } };
    };
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[valid_domain]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DomainValidatorDirective,
            multi: true,
        },
    ],
})
export class DomainValidatorDirective implements Validator {
    @Input('valid_domain')
    validate(control: AbstractControl): ValidationErrors | null {

        return validateDomain()(control);
    }
}
