import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
@Component({
    selector: 'app-configuration-menu',
    templateUrl: './configuration.menu.component.html',
})
export class ConfigurationMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit() {
        if (this.getAuth(['MANAGE_BANKS'])) {
            this.model.push(
                {
                    label: 'Configuración de Bancos',
                    icon: 'pi pi-building',
                    routerLink: ['/configuration/banks'],
                },
                /*{
                    label: 'Transferencias',
                    icon: 'pi pi-arrows-h',
                    routerLink: ['/configuration/configTransfer'],
                },*/
            );
        }

        // if (this.getAuth(['MANAGE_COMMISSION'])) {
        if (this.getAuth(['MANAGE_BANKS'])) {
            this.model.push(
                {
                    label: 'Configuración Comisión',
                    icon: 'pi pi-credit-card',
                    routerLink: ['/configuration/commission'],
                }
            );
        }
        // if (this.getAuth(['MANAGE_COMPENSATIONS'])) {
        if (this.getAuth(['MANAGE_CLOSE_CASH'])) {
            this.model.push(
                {
                    label: 'Cierres de Caja',
                    icon: 'pi pi-inbox',
                    routerLink: ['/configuration/compensation'],
                }
            );
        }
    }
}
