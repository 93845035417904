<div class="container">
    <div class="row">
        <div class="col">
            <form class="form" [formGroup]="permissionForm" (ngSubmit)="validatePermission()">
                <div class="row">
                    <div class="col">
                        <label for="system">Sistema</label>
                        <select [compareWith]="compareFn" id="system" formControlName="system" class="form-control">
                            <option [ngValue]="null" disabled>Seleccione Sistema..</option>
                            <option *ngFor="let system of systems" [ngValue]="system">{{system.name}}
                            </option>
                        </select>
                        <mat-error *ngIf="permissionForm.controls.system.errors?.required &&
                                permissionForm.get('system').touched">
                            Seleccione <strong>sistema.</strong>
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input id="name" name="name" placeholder="Nombre" appInputTextAndNumber maxlength="60"
                                class="uppercase form-control" type="text" formControlName="name" autocomplete="off">
                            <mat-error *ngIf="permissionForm.controls.name.errors?.required &&
                                permissionForm.get('name').touched">
                                Campo <strong>requerido.</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="domainAction">Acción de dominio</label>
                            <input id="domainAction" name="domainAction" placeholder="Acción de dominio" type="text"
                                formControlName="domainAction" class="uppercase form-control" appInputTextAndUndescore
                                maxlength="60" autocomplete="off">
                            <mat-error *ngIf="permissionForm.controls.domainAction.errors?.required &&
                                permissionForm.get('domainAction').touched">
                                Campo <strong>requerido.</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div *ngIf="this.getAuth(['MANAGE_USERS'])" class="col">
                        <div class="d-flex justify-content-center m-2">
                            <mat-checkbox class="check" formControlName="active">
                                Activo
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row right mt-2">
                    <div class="col">
                        <button type="submit" pButton pRipple label="{{isNew?'Guardar':'Actualizar'}}" icon="pi pi-save"
                            class="p-button-info m-r-5"></button>
                    </div>
                    <div class="col">
                        <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                            class=""></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
