<div class="card">
    <div class="container-fluid">
        <div class="row">
            <div class="text-right col-sm-12 col-md-5 col-lg-3 py-3">
                Busque una persona:
            </div>
            <div class="col my-auto">
                <p-autoComplete [inputStyle]="{ 'height': '40px'}" #autocomplete autoHighlight="true" [autofocus]="true"
                    [(ngModel)]="selectedPerson" [suggestions]="persons" (keydown.enter)="addPerson()"
                    (keypress)="omit_special_char($event)" (keyup)="onKey($event)" (click)="addPerson()"
                    (completeMethod)="onSearchPerson($event)" (keydown.tab)="addPerson()" [minLength]="3"
                    [maxlength]="25" placeholder="Nombre ó Identificación" [dropdown]="true" [delay]="1200"
                    emptyMessage="No hay resultado..">
                    <ng-template let-row pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <div class="lineProd">
                                <div class="center code"> {{row.dni}} </div>
                                <div class="left name elli"> {{row.fullName}} </div>
                            </div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 pt-3" *ngIf="numberResultsPersons >=1 && autoCompleteValue>2">
                {{numberResultsPersons}} resultados
            </div>
        </div>
    </div>
</div>
