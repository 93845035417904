import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Person } from 'src/app/model.ts/person.model';

import { GenerateUserComponent } from '../generate-user/generate-user.component';
import { GenerateNewUserView } from './generate-new-user.view';
import { GenerateNewUserPresenter } from './presenter/generate-new-user.presenter';

@Component({
    selector: 'app-generate-new-user',
    templateUrl: './generate-new-user.component.html',
    styleUrls: ['./generate-new-user.component.scss'],
})
export class GenerateNewUserComponent
    extends AbstractView
    implements OnInit, GenerateNewUserView {
    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        public dialogService: DialogService,
        private messageService: MessageService,
        private generateNewUserPresenter: GenerateNewUserPresenter
    ) {
        super(messageService, router);
        generateNewUserPresenter.view = this;
    }
    value = '';
    persons: Person[] = [];
    selectedPerson: Person;
    autoCompleteValue = 0;
    numberResultsPersons: number;
    currentUser: any = {};
    ngOnInit(): void {
        //   this.generateNewUserPresenter.getPersons();
    }

    addPerson() {
        if (typeof this.selectedPerson !== 'string') {
            if (this.selectedPerson) {
                if (this.selectedPerson.user?.id) {
                    if (this.selectedPerson.user.active) {
                        this.showInfo(
                            'Información',
                            'Este usuario ya está importado'
                        );
                    } else {
                        this.showInfo(
                            'Información',
                            'Este usuario está inactivo'
                        );
                    }
                    this.selectedPerson = null;
                    this.autoCompleteValue = 0;
                    return;
                } else {
                    this.showGenerateUserComponent('Nuevo Usuario');
                }
                this.autoCompleteValue = 0;
            }
        }
    }

    showGenerateUserComponent(title: string) {
        if (
            localStorage.getItem('currentPerson') !== null &&
            localStorage.getItem('currentPerson') !== ''
        ) {
            this.currentUser = JSON.parse(localStorage.getItem('currentPerson'));
        }
        const refNew = this.ref;
        this.ref = this.dialogService.open(GenerateUserComponent, {
            header: title,
            width: '100%',
            height: 'auto',
            data: { person: this.selectedPerson.id, isNew: true, currentUser: this.currentUser },
        });
        refNew.close();

        this.ref.onClose.subscribe(() => {
            this.generateNewUserPresenter.personService.isUpdateListUsers =
                true;
        });
    }

    onSearchPerson(event) {
        this.value = event.query;
        this.generateNewUserPresenter.getPersonsBySearch();
    }

    omit_special_char(event) {
        let k;
        k = event.charCode; // k = event.keyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k === 8 ||
            k === 32 ||
            (k >= 48 && k <= 57)
        );
    }

    onKey(event) {
        this.autoCompleteValue = event.target.value.length;
    }
}
