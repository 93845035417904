import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { UserInput, UserPasswordInput } from 'src/app/model.ts/user.model';

import { PermissionInput, RolInput } from '../model.ts/rol.model';
import { UserLogInput } from '../model.ts/user-log.model';

@Injectable({
    providedIn: 'root',
})
export class UsersRolesPermissionsService {
    constructor(private apollo: Apollo) { }

    LIST_USERS = gql`
        query users(
            $page: Int!
            $size: Int!
            $value: String!
            $active: Boolean!
        ) {
            users(page: $page, size: $size, value: $value, active: $active) {
                page
                size
                totalPages
                totalElements
                data {
                    id
                    userName
                    pin
                    active
                    password
                    person {
                        id
                        fullName
                        identificationType
                        dni
                        email
                        referenceId
                        active
                    }
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                }
            }
        }
    `;

    LIST_ROLES = gql`
        query rol(
            $page: Int!
            $size: Int!
            $value: String
            $active: Boolean!
            $systemId: ID
        ) {
            rol(
                page: $page
                size: $size
                value: $value
                active: $active
                systemId: $systemId
            ) {
                page
                size
                data {
                    id
                    name
                    active
                    permissions {
                        id
                        name
                        domainAction
                        active
                    }
                    system {
                        id
                        name
                        active
                        code
                    }
                }
            }
        }
    `;

    USER_BY_ID = gql`
        query userById($userId: String!) {
            userById(userId: $userId) {
                id
                userName
                password
                pin
                warehouse {
                    id
                    name
                    address
                    phone
                    referenceId
                    email
                    valuedMaximumStock
                    active
                    acopio
                    supply
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    establishment {
                        id
                        name
                        number
                        referenceId
                    }
                    directTransfer
                }
                active
                resetPassword
                roles {
                    id
                    name
                    active
                    permissions {
                        id
                        name
                        domainAction
                        active
                    }
                    system {
                        id
                        name
                        active
                        code
                    }
                }
                oldPassword
                person {
                    id
                    fullName
                    dni
                    email
                    active
                    referenceId
                    phone
                    identificationType
                    userName
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                }
                warehouses {
                    id
                    name
                    address
                    phone
                    referenceId
                    email
                    valuedMaximumStock
                    active
                    acopio
                    supply
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    establishment {
                        id
                        name
                        number
                        referenceId
                    }
                    directTransfer
                }
                processTasks {
                    id
                    name
                    code
                    active
                    order
                    visible
                    needReserve
                    userName
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    `;

    LIST_PERMISSIONS = gql`
        query permission(
            $page: Int!
            $size: Int!
            $value: String
            $active: Boolean!
            $systemId: ID
        ) {
            permission(
                page: $page
                size: $size
                value: $value
                active: $active
                systemId: $systemId
            ) {
                page
                size
                data {
                    id
                    name
                    domainAction
                    active
                    system {
                        id
                        name
                        active
                        code
                    }
                }
            }
        }
    `;

    SAVE_PERMISSION = gql`
        mutation savePermission($permission: PermissionInput!) {
            savePermission(permission: $permission) {
                id
                name
                domainAction
                active
                system {
                    id
                    name
                    active
                    code
                }
            }
        }
    `;

    PERMISSION_BY_ID = gql`
        query permissionById($permissionId: String!) {
            permissionById(permissionId: $permissionId) {
                id
                name
                domainAction
                active
                system {
                    id
                    name
                    active
                    code
                }
            }
        }
    `;

    ROL_BY_ID = gql`
        query rolById($rolId: ID!) {
            rolById(rolId: $rolId) {
                id
                name
                active
                system {
                    id
                    name
                    active
                    code
                }
                permissions {
                    id
                    name
                    domainAction
                    active
                }
            }
        }
    `;
    SAVE_USER = gql`
        mutation saveUser($user: UserInput!) {
            saveUser(user: $user) {
                id
                userName
            }
        }
    `;
    SAVE_ROL = gql`
        mutation saveRol($rol: RolInput!) {
            saveRol(rol: $rol) {
                id
                name
                active
                permissions {
                    id
                    name
                    domainAction
                    active
                }
                system {
                    id
                    name
                    active
                    code
                }
            }
        }
    `;

    UPDATE_PASSWORD_REQUEST = gql`
        mutation updatePasswordUser($user: UserPasswordInput) {
            updatePasswordUser(user: $user) {
                id
            }
        }
    `;

    PASSWORD_RESET_INSIDE = gql`
        mutation passwordResetInside($user: UserPasswordInput!) {
            passwordResetInside(user: $user)
        }
    `;

    RESYNCHRONIZE_USERS = gql`
        mutation resynchronizeUsers {
            resynchronizeUsers
        }
    `;


    USERS_COUNT_STATUS = gql`
        query usersStatusCount($status: Boolean!) {
            usersStatusCount(status: $status)
        }
    `;

    SAVE_USER_LOG = gql`
    mutation saveUserLog ($userLog: UserLogInput){
        saveUserLog(userLog: $userLog)
      }
`;


    LIST_USERS_LOGS = gql`
        query userLog (
            $page: Int!
            $size: Int!
            $userId: ID
            ){
                userLog(
                    page: $page
                    size: $size
                    userId: $userId
                    ) {
                        page
                        size
                        data {
                                id
                                user{
                                active
                                userName
                                }
                                transactionBy
                                transaction
                                transactionUserLogType
                                updatedAt
                            }
            }
        }
        `;

    omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    omitType(model) {
        return JSON.parse(JSON.stringify(model), this.omitTypename);
    }

    savePermission(permission: PermissionInput) {
        permission.id = !permission.id ? null : permission.id;
        return this.apollo.mutate({
            mutation: this.SAVE_PERMISSION,
            variables: { permission },
        });
    }
    listPermissionsByStatus(
        page: number,
        size: number,
        value: string,
        active: boolean,
        systemId: string
    ) {
        return this.apollo.query<any>({
            query: this.LIST_PERMISSIONS,
            variables: { page, size, value, active, systemId },
        });
    }
    listRolesByStatus(
        page: number,
        size: number,
        value: string,
        active: boolean,
        systemId: string
    ) {
        return this.apollo.query<any>({
            query: this.LIST_ROLES,
            variables: { page, size, value, active, systemId },
        });
    }
    rolById(rolId: string) {
        return this.apollo.query<any>({
            query: this.ROL_BY_ID,
            variables: { rolId },
        });
    }
    listUsersByStatus(
        page: number,
        size: number,
        value: string,
        active: boolean
    ) {
        return this.apollo.query<any>({
            query: this.LIST_USERS,
            variables: { page, size, value, active },
        });
    }
    userById(userId: string) {
        return this.apollo.query<any>({
            query: this.USER_BY_ID,
            variables: { userId },
        });
    }

    permissionById(permissionId: string) {
        return this.apollo.query<any>({
            query: this.PERMISSION_BY_ID,
            variables: { permissionId },
        });
    }

    saveUser(user: UserInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_USER,
            variables: { user },
        });
    }
    saveRol(rol: RolInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_ROL,
            variables: { rol },
        });
    }
    updatePassword(user: UserPasswordInput) {
        return this.apollo.mutate({
            mutation: this.UPDATE_PASSWORD_REQUEST,
            variables: { user },
        });
    }

    updatePasswordInside(user: UserInput) {
        return this.apollo.mutate({
            mutation: this.PASSWORD_RESET_INSIDE,
            variables: { user },
        });
    }

    resynchronizeUsers() {
        return this.apollo.mutate({
            mutation: this.RESYNCHRONIZE_USERS,
            variables: {},
        });
    }

    usersStatusCount(status: boolean) {
        return this.apollo.query<any>({
            query: this.USERS_COUNT_STATUS,
            variables: { status },
        });
    }

    saveUserLog(userLog: UserLogInput) {
        return this.apollo.query<any>({
            query: this.SAVE_USER_LOG,
            variables: { userLog },
        });
    }

    listUsersLogs(
        page: number,
        size: number,
        userId: string
    ) {
        return this.apollo.query<any>({
            query: this.LIST_USERS_LOGS,
            variables: { page, size, userId },
        });
    }
}
