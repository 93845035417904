import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { ConfigurationType } from 'src/app/model.ts/user-dynamics.model';
import { ConfigDynamicsService } from 'src/app/services/config-dynamics.service';
import { UserService } from 'src/app/services/user.service';

import { AppTopbarView } from '../app.topbar.view';
import { Person } from './../../../model.ts/person.model';

@Injectable()
export class AppTopbarPresenter implements Presenter {
    public static doingSomething = false;
    view: AppTopbarView;

    constructor(
        private userService: UserService,
        private configDynamicsService: ConfigDynamicsService
    ) { }

    getCurrentPerson() {
        if (localStorage.getItem('currentPerson') == null) {
            this.view.blockUi();
            this.userService.getCurrentPerson().subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.currentPerson = data.findPersonByUserName;

                        if (this.view.currentPerson.user.resetPassword) {
                            this.view.changePassword(false, 'OBLIGATORIO');
                        }

                        localStorage.setItem(
                            'currentPerson',
                            JSON.stringify(data.findPersonByUserName)
                        );
                        this.view.setRoles(
                            data.findPersonByUserName.user?.roles
                        );
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'Reinicie sesión para ver el usuario actual'
                        );
                    }
                },
                () => {
                    this.view.showError('Atención', 'Usuario no encontrado');
                }
            );
        } else {
            this.view.currentPerson = JSON.parse(
                localStorage.getItem('currentPerson')
            ) as Person;
            this.view.setRoles(this.view.currentPerson.user?.['roles']);
        }
    }

    verifyPassword(user: string, password: string, system: string) {
        this.userService.doLoginRest(user, password, system).subscribe(
            (data) => {
                this.view.changePassword(true, '');
            },
            () => {
                this.view.showWarn('', 'Contraseña incorrecta.');
            }
        );
    }

    findByConfUserConfigurationType(typeConfig: ConfigurationType) {
        this.configDynamicsService
            .findByConfUserConfigurationType(typeConfig)
            .subscribe(
                (data) => {
                    this.view.selectedConfigUserDynamic =
                        data.data.findByConfUserConfigurationType;
                    this.view.addValuesForm();
                },
                (error) => {
                    this.view.showError('Advertencia', error);
                }
            );
    }

    syncStockDynamics() {
        this.view.blockUi();
        this.configDynamicsService
            .syncStockDynamics()
            .subscribe(
                (data) => {
                    this.view.showSuccess('Correcto', 'Sincronizando Stock.');
                    this.view.displaySyncStock = false;
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Advertencia', error);
                }
            );
    }

    saveConfUserDynamics() {
        this.configDynamicsService
            .saveConfUserDynamics(
                this.configDynamicsService.omitType(
                    this.view.selectedConfigUserDynamic
                )
            )
            .subscribe(
                (data) => {
                    this.view.showSuccess('Correcto', 'Actualización correcta');
                    this.view.displayConfigDynamics = false;
                },
                (error) => {
                    this.view.showError('Advertencia', error);
                }
            );
    }
}
