import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector: 'app-price-menu',
    templateUrl: './price-menu.component.html',
})
export class PriceMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }
    ngOnInit() {
        if (this.getAuth(['MANAGE_PRICESs'])) {
            this.model.push({
                label: 'Precios',
                icon: 'pi pi-fw pi-dollar',
                routerLink: ['/prices/manage-prices'],
            });
        }
        if (this.getAuth(['PRICES_LIST'])) {
            this.model.push({
                label: 'Lista de Precios',
                icon: 'pi pi-sitemap',
                routerLink: ['/price-lists/price-list'],
            });
        }
        if (this.getAuth(['PRICES_HIERACHY'])) {
            this.model.push({
                label: 'Administrar Jerarquia',
                icon: 'pi pi-sitemap',
                routerLink: ['/manage-hierarchy/manage-hierarchy-vendors'],
            });
        }
        if (this.getAuth(['MANAGE_PRICES'])) {
            this.model.push({
                label: 'Precios PDV',
                icon: 'pi pi-upload',
                routerLink: this.getAuth(['UPLOAD_PRICES']) ? ['/price-upload/price-upload-base'] : ['/price-upload/price-product'],
            });
        }
        if (this.getAuth(['MANAGE_PRICES_DEALER'])) {
            this.model.push({
                label: 'Precios Distribuidor',
                icon: 'pi pi-briefcase',
                routerLink: ['/dealer-price-upload/dealer-price-upload'],
            });
        }
    }
}
