import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PermissionInput } from 'src/app/model.ts/rol.model';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { PermissionsView } from '../permissions.view';

@Injectable()
export class PermissionsPresenter implements Presenter {
    view: PermissionsView;

    constructor(
        private userRolesPermissions: UsersRolesPermissionsService,
        private usersRolesPermissionsService: UsersRolesPermissionsService,
        private systemsRolesPermissionsService: SystemsRolesPermissionsService
    ) {}

    listPermissions() {
        this.view.blockUi();
        this.userRolesPermissions
            .listPermissionsByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                this.view.activePermissions,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.permissions = data.permission.data;
                        this.view.totalElements = data.permission.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen permisos cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listActiveSystem() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(0, 10, '', true)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.systems = data.systems.data;
                        this.view.totalElements = data.systems.size;
                        this.view.systems.unshift(this.view.systemAll);
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listPermissionsBySystemId() {
        this.view.blockUi();
        this.userRolesPermissions
            .listPermissionsByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                this.view.activePermissions,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.permissions = data.permission.data;
                        this.view.totalElements = data.permission.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen permisos cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberPermissionsBySystemId() {
        this.view.blockUi();
        this.userRolesPermissions
            .listPermissionsByStatus(
                0,
                1000,
                this.view.value,
                !this.view.activePermissions,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberPermissions = data.permission.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen permisos cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberPermissions() {
        this.view.blockUi();
        this.userRolesPermissions
            .listPermissionsByStatus(
                0,
                1000,
                this.view.value,
                !this.view.activePermissions,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberPermissions = data.permission.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen permisos cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    savePermission(permission: PermissionInput) {
        this.view.blockUi();
        this.usersRolesPermissionsService.savePermission(permission).subscribe(
            () => {
                this.view.showSuccess('', 'Permiso guardado correctamente');
                this.view.listPermissions();
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    permissionById(permissionId: string) {
        this.view.blockUi();
        this.usersRolesPermissionsService
            .permissionById(permissionId)
            .subscribe(
                ({ data }) => {
                    this.view.selectedPermission = data.permissionById;
                    this.view.showGeneratePermissionComponent(
                        'Editar Permiso ' + data.permissionById.name,
                        false,
                        null
                    );
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }
}
