import { Injectable } from '@angular/core';
import { openDB, DBSchema, IDBPDatabase, deleteDB } from 'idb';
import { PriceProduct } from '../model.ts/price.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


interface MyDB extends DBSchema {
  'price-products': {
    key: string;
    value: PriceProduct;
  };
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  private dbName = 'price-product-db';
  @BlockUI() blockUI: NgBlockUI;
  private dbPromise: Promise<IDBPDatabase<MyDB>>;

  constructor() {
    this.dbPromise = openDB<MyDB>('price-product-db', 1, {
      upgrade(db) {
        db.createObjectStore('price-products', { keyPath: 'productuuid' });
      },
    });
  }

  async addPriceProducts(products: PriceProduct[]): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction('price-products', 'readwrite');
    products.forEach(product => {
      tx.store.put(product);
    });
    await tx.done;
  }

    // Método para actualizar o agregar productos
    async updatePriceProducts(products: PriceProduct[]): Promise<void> {
      this.blockUI.start('Actualizando datos');
      const db = await this.dbPromise;
      const tx = db.transaction('price-products', 'readwrite');
      for (const product of products) {
        await tx.store.put(product);
      }
      await tx.done;
      this.blockUI.stop();
    }

  async getAllPriceProducts(): Promise<PriceProduct[]> {
    const db = await this.dbPromise;
    const allProducts = await db.getAll('price-products');

    // Ordenar los productos por productReferenceCode
    allProducts.sort((a, b) => {
      if (a.productReferenceCode < b.productReferenceCode) {
        return -1;
      }
      if (a.productReferenceCode > b.productReferenceCode) {
        return 1;
      }
      return 0;
    });

    this.blockUI.stop();

    return allProducts;
  }

  async clearPriceProducts(): Promise<void> {
    this.blockUI.start('Sincronizando con la lista de precios del PDV');
    const db = await this.dbPromise;
    const tx = db.transaction('price-products', 'readwrite');
    tx.store.clear();
    await tx.done;
  }

  async deleteDatabase(): Promise<void> {
    this.blockUI.start('Cargando');
    try {
      await deleteDB(this.dbName, {
        blocked() {
          console.log('No se pudo borrar la base de datos debido a que está bloqueada.');
        },
      });
      console.log('Base de datos borrada exitosamente.');
    } catch (error) {
      console.error('Error al borrar la base de datos:', error);
    }
    this.blockUI.stop();
  }

}
