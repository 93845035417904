import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-price-pdv-menu',
  templateUrl: './price-pdv-menu.component.html',
})
export class PricePdvMenuComponent extends AbstractView implements OnInit {
  public model: any[] = [];

  constructor(public messageService: MessageService, public router: Router) {
    super(messageService, router);
  }

  ngOnInit(): void {
    if (this.getAuth(['MANAGE_PRICES_PDV'])) {
      this.model.push({
          label: 'Asignar precios PDV',
          icon: '',
          routerLink: [`/price-especial-pdv`],
      });
  }
  }

}
