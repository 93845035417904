import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { AbstractView } from 'src/app/core/abstract_view';
import { User, UserInput } from 'src/app/model.ts/user.model';

import { ChangePasswordComponent } from '../change-password/change-password.component';
import { GenerateNewUserComponent } from '../generate-new-user/generate-new-user.component';
import { GenerateUserComponent } from '../generate-user/generate-user.component';
import { UsersPresenter } from './presenter/users.presenter';
import { UsersView } from './users.view';
import { UserLogsComponent } from '../../user-logs/user-logs.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent
    extends AbstractView
    implements OnInit, AfterViewInit, AfterViewChecked, UsersView {
    users: UserInput[] = [];
    selectedUser: User;
    numberUsers = 0;
    value = '';
    activeUser = true;
    showNewButton = true;

    totalElements = 0;
    totalPages = 0;
    page = 0;
    size = 50;
    pageSince = 1;
    pageUntil = 50;
    elements = [50, 100, 200, 500];

    isDevelop = false;

    currentUser: any = {};
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    public ref;

    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public dialogService: DialogService,
        private usersPresenter: UsersPresenter,
        private messageService: MessageService
    ) {
        super(messageService, router);
        usersPresenter.view = this;

        if (!this.getAuth(['MANAGE_USERS'])) {
            this.showError('', 'Sin autorización');
            this.redirectTo('main');
        }
        if (this.getAuth(['SYNC_USERS'])) {
            this.isDevelop = true;
        }
    }

    ngOnInit(): void {
        this.verifyStatusUser();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchUser();
    }

    ngAfterViewChecked(): void {
        // check if generate-user was imported a user
        if (this.usersPresenter.personService.isUpdateListUsers) {
            this.verifyStatusUser();
            this.usersPresenter.personService.isUpdateListUsers = false;
        }
    }

    setFocusSearchUser() {
        /* setTimeout(() =>
            this.renderer.selectRootElement('#searchUser').focus()
        ); */
    }

    clickActivePersonChecked() {
        this.page = 0;
        this.paginator.changePage(0);
        this.verifyStatusUser();
    }

    verifyStatusUser() {
        if (this.activeUser) {
            this.showNewButton = true;
            this.listActiveUsers();
        } else {
            this.listInactiveUsers();
            this.showNewButton = false;
        }
        this.usersPresenter.getNumberUsers();
    }

    listActiveUsers() {
        this.usersPresenter.listUsers(true);
    }

    listInactiveUsers() {
        this.usersPresenter.listUsers(false);
    }

    openGenerateNewUserComponent() {
        this.showGenerateNewUserComponent('Nuevo Usuario');
    }

    showGenerateNewUserComponent(title: string) {
        this.ref = this.dialogService.open(GenerateNewUserComponent, {
            header: title,
            width: '50%',
            height: 'auto',
            contentStyle: { overflow: 'visible' },
        });
    }

    editGenerateUserComponent(user: User) {
        // this.usersPresenter.userById(user.id);
        if (
            localStorage.getItem('currentPerson') !== null &&
            localStorage.getItem('currentPerson') !== ''
        ) {
            this.currentUser = JSON.parse(localStorage.getItem('currentPerson'));
        }

        this.ref = this.dialogService.open(GenerateUserComponent, {
            header: 'Editar Usuario ' + user.userName,
            width: '100%',
            height: 'auto',
            data: { user: user.id, isNew: false, currentUser: this.currentUser },
        });
        this.ref.onClose.subscribe(() => {
            this.verifyStatusUser();
        });
    }

    showGenerateUserComponent(title: string, isNew: boolean) {
        this.ref = this.dialogService.open(GenerateUserComponent, {
            header: title,
            width: '100%',
            height: 'auto',
            data: { user: this.selectedUser.id, isNew },
        });
        this.ref.onClose.subscribe(() => {
            this.verifyStatusUser();
        });
    }

    changePasswordUserComponent(user: User) {
        this.selectedUser = user;
        this.showChangePassworComponent(true);
    }

    showUsersLogs(user: User) {
        this.ref = this.dialogService.open(UserLogsComponent, {
            header: 'Bitácora de usuario',
            width: '75%',
            height: 'auto',
            closeOnEscape: true,
            data: { user },
        });
    }

    showChangePassworComponent(isAdmin: boolean) {
        if (
            localStorage.getItem('currentPerson') !== null &&
            localStorage.getItem('currentPerson') !== ''
        ) {
            this.currentUser = JSON.parse(localStorage.getItem('currentPerson'));
        }
        this.ref = this.dialogService.open(ChangePasswordComponent, {
            header: 'Reinicio de contraseña',
            closeOnEscape: true,
            data: { user: this.selectedUser, isAdmin, currentUser: this.currentUser },
        });
    }

    /* onSearchChange(value) {
        setTimeout(() => {
            this.reset();
            this.value = value.target.value;
            this.verifyStatusUser();
        }, 2000);
    } */

    onSearchChangeAu(value) {
        this.value = value.query;
        this.verifyStatusUser();
        this.reset();
    }

    clickActiveUserChecked() {
        this.verifyStatusUser();
    }

    reset() {
        this.page = 0;
        this.pageSince = 1;
        this.paginator.changePage(0);
    }

    paginate($event) {
        this.page = $event['page'];
        if (this.page === 0) {
            this.pageSince = 1;
            this.pageUntil = this.size;
        } else {
            this.pageSince = this.size * this.page + 1;
            this.pageUntil = this.size * (this.page + 1);
        }
        this.verifyStatusUser();
    }

    resynchronizeUsers() {
        this.usersPresenter.resynchronizeUsers();
    }

    allPersons() {
        this.value = '';
        this.verifyStatusUser();
    }
}
