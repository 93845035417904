<p-toast></p-toast>

<div class="continer-card">
    <div class="card card-botonera">
        <div class="row justify-content-between">
            <div class="pl-3">
                <div *ngIf="showNewButton">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button bc-primary p-mr-2"
                        (click)="openGenerateNewUserComponent()"></button>
                </div>
            </div>
            <!-- <div class="ml-5 ">
                            <button pButton pRipple label="Sincroniza Usuarios" icon="pi pi-refresh"
                                class="p-button-primary p-mr-2" (click)="openGenerateUserComponent()"></button>
                        </div> -->
            <div class="pr-5">
                <div class="row">

                    <div *ngIf="isDevelop" class="pr-3">
                        <button pButton label="Sincronizar" class="p-button bc-primary p-mr-2"
                            (click)="resynchronizeUsers()"><i class="material-icons pr-1">cloud_sync</i></button>
                    </div>

                    <div class="pr-3 pt-2">
                        <h5>Ver {{numberUsers}} usuarios {{activeUser ?'Inactivos':'Activos'}}
                        </h5>
                    </div>
                    <div class="pr-3 pt-2">
                        <p-inputSwitch [(ngModel)]="activeUser" (onChange)="clickActiveUserChecked()">
                        </p-inputSwitch>
                    </div>
                    <!-- <span class="p-input-icon-right">
                        <i style="cursor: pointer;" class="pi pi-times-circle" (click)="allPersons()"></i>
                        <input id="searchUser" style="height: 35px;" type="text" autocomplete="off"
                            (onClear)="allPersons()" pInputText placeholder="Buscar usuario" name="selectWarehouse"
                            [(ngModel)]="value" (keyup)="onSearchChange($event)" />
                        </span> -->
                    <p-autoComplete (onClear)="allPersons()" [delay]="2000" placeholder="Buscar usuario"
                        (completeMethod)="onSearchChangeAu($event)" [type]="'search'">
                    </p-autoComplete>
                </div>
            </div>
        </div>
    </div>

    <p-table #dt [value]="users" styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines">
        <ng-template pTemplate="header" pTemplate="header">
            <tr>
                <th scope="col" [ngStyle]="{'width': '14%','text-align':'center'}">Usuario </th>
                <th scope="col" [ngStyle]="{'width': '24%','text-align':'center'}">Nombre</th>
                <th scope="col" [ngStyle]="{'width': '24%','text-align':'center'}">Correo </th>
                <!-- <th scope="col" [ngStyle]="{'width': '8%'}" class="text-center">Documento </th>
                        <th scope="col" [ngStyle]="{'width': '10%'}">Identificación </th> -->
                <!--  <th scope="col" [ngStyle]="{'width': '23%'}" class="text-center">Punto de Venta </th> -->
                <th scope="col" [ngStyle]="{'width': '15%'}" class="text-center">Acción</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" pTemplate="body" let-user>
            <tr>
                <td class="elli">{{user.userName}}</td>
                <td>{{user.person.fullName}}</td>
                <td class="elli">{{user.person.email}}
                </td>
                <!-- <td class="elli">{{user.establishment?.name}}</td> -->
                <td style="text-align: center;">
                    <i *ngIf="this.getAuth(['MANAGE_USERS'])" class="material-icons color-icon-primary" type="button"
                        pTooltip="Editar {{user.userName}}" tooltipPosition="left"
                        (click)="editGenerateUserComponent(user)">drive_file_rename_outline
                    </i>

                    <i *ngIf="this.getAuth(['MANAGE_USERS'])" class="material-icons color-icon-primary pl-3"
                        type="button" pTooltip="Reinicio de contraseña {{user.userName}}" tooltipPosition="left"
                        (click)="changePasswordUserComponent(user)">vpn_key
                    </i>

                    <i *ngIf="this.getAuth(['MANAGE_USERS'])" class="material-icons color-icon-primary pl-3"
                        type="button" pTooltip="Actualizaciones" tooltipPosition="left"
                        (click)="showUsersLogs(user)">receipt_long
                    </i>
                </td>
            </tr>
        </ng-template>
        <!--  <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Existen {{totalElements ? totalElements : 0 }} usuarios {{activeUser ?'Activos':'Inactivos'}}
            </div>
        </ng-template> -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No se ha encontrado registros.</td>
            </tr>
        </ng-template>
    </p-table>
    <!-- <h2 class="center m-4 text-warning" *ngIf="totalElements==0">No hay resultados de su busqueda</h2>
    <p-paginator #paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)">
    </p-paginator> -->
    <div class="row p-justify-center">
        <span class="p-paginator-current ng-star-inserted">Registros {{ pageSince }} de {{ pageUntil }} del total
            {{ totalElements }}</span>
        <p-paginator #paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)">
        </p-paginator>
        <p-dropdown class="pt-3 dp-paginate" [options]="elements" [(ngModel)]="size" [showClear]="false"
            (onChange)="this.verifyStatusUser()"></p-dropdown>
    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>
