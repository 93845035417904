<nav mat-tab-nav-bar>
    <div *ngFor="let link of navLinks">
        <a class="lbl-nav" *ngIf="link.visible" mat-tab-link [routerLink]="link.link" routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive">
            <i class="{{link.icon}} pr-2"></i>
            <span> {{link.label}}</span>
        </a>
    </div>
</nav>
<router-outlet></router-outlet>
