<div class="container">
    <div class="row">
        <div class="col">
            <form class="form" [formGroup]="rolForm" (ngSubmit)="validateRol()">
                <div class="row">
                    <div class="col">
                        <label for="system">Sistema</label>
                        <select [compareWith]="compareFn" id="system" formControlName="system" class="form-control"
                            (change)="onSystemChange()">
                            <option [ngValue]="null" disabled>Seleccione Sistema..</option>
                            <option *ngFor="let system of systems" [ngValue]="system">{{system.name}}
                            </option>
                        </select>
                        <mat-error *ngIf="rolForm.controls.system.errors?.required &&
                                rolForm.get('system').touched">
                            Seleccione <strong>sistema.</strong>
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="form-group">
                            <label for="code">Rol</label>
                            <input id="name" placeholder="Nombre" appInputTextAndNumber maxlength="60" type="text"
                                class="uppercase form-control" formControlName="name" autocomplete="off">
                            <mat-error *ngIf="rolForm.controls.name.errors?.required &&
                                rolForm.get('name').touched">
                                Campo <strong>requerido.</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="permisionLabel">
                            <div>Permisos</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p-listbox [ngModelOptions]="{standalone: true}" [options]="permissions" [showToggleAll]="true"
                            (onClick)="onItemSelect($event.value)" [(ngModel)]="selectedPermissions"
                            [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true"
                            optionLabel="name" [listStyle]="{'max-height':'180px'}" [style]="{'width':'auto'}">
                            <ng-template let-per1 pTemplate="item">
                                <div class="p1-item">
                                    <div pTooltip="{{per1.domainAction}}">{{per1.name}}</div>
                                </div>
                            </ng-template>
                        </p-listbox>
                    </div>
                </div>
                <div class="row  m-3">
                    <div id="active" class="col">
                        <mat-checkbox formControlName="active">Activo</mat-checkbox>
                    </div>
                </div>

                <div class="row right">
                    <div class="col">
                        <button type="submit" pButton pRipple label="{{isNew?'Guardar':'Actualizar'}}" icon="pi pi-save"
                            class="p-button-info m-r-5"></button>
                    </div>
                    <div class="col">
                        <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                            class=""></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
