<!-- <fieldset [disabled]="false"> -->
<div>
    <div class="row">
        <div class="col">
            <div class="card">
                <p-tabView>
                    <p-tabPanel header="Megapos">
                        <div class="row">
                            <div class="col">
                                <div class="punto">Almacén:</div>
                                <div>
                                    <div class="row" style="justify-content: center">
                                        <div id="margin-establishment">
                                            <div class="
                                                        d-flex
                                                        justify-content-center
                                                        align-self-center
                                                    ">
                                                <div [ngClass]="{
                                                            'format-select':
                                                                showSelectedWarehouse ===
                                                                true
                                                        }">
                                                    <div *ngIf="
                                                                showSelectedWarehouse
                                                            " class="
                                                                elli
                                                                flex-center
                                                                product
                                                            ">
                                                        <div class="
                                                                    lineParametersEstablishment
                                                                ">
                                                            <div [ngClass]="{
                                                                        elli:
                                                                            warehouseSelected
                                                                                ?.name
                                                                                .length >=
                                                                            25
                                                                    }" tooltipPosition="top" pTooltip="{{
                                                                        warehouseSelected?.name
                                                                    }} " class="name">
                                                                {{
                                                                        warehouseSelected?.name
                                                                    }}
                                                            </div>
                                                            <div class="
                                                                        btn
                                                                        ui-state-default
                                                                        ui-corner-all
                                                                        cancel
                                                                    " (click)="
                                                                        clearEstablishment()
                                                                    ">
                                                                <i class="
                                                                            material-icons
                                                                        ">close</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="
                                                            warehouses.length !==
                                                            0
                                                        ">
                                                    <ng-multiselect-dropdown *ngIf="
                                                                !showSelectedWarehouse
                                                            " [ngModelOptions]="{
                                                                standalone: true
                                                            }" style="width: 500px" [placeholder]="
                                                                'TODOS LOS ALMACENES...'
                                                            " [settings]="
                                                                dropdownSettings
                                                            " [data]="
                                                                warehouses
                                                            " [(ngModel)]="
                                                                warehouseSelected
                                                            " (onSelect)="
                                                                onWarehouseSelect(
                                                                    $event
                                                                )
                                                            ">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                                <div *ngIf="
                                                            warehouses.length ===
                                                            0
                                                        " class="card-not-system">
                                                    <i class="
                                                                pi pi-info-circle
                                                            "></i>
                                                    No hay almacenes
                                                    cargados en la base de datos
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <!-- <p-tabPanel header="Abastecimiento">
                            <div class="row">
                                <div *ngIf="warehouses.length !== 0">
                                    <div class="col">
                                        <p-listbox
                                            [ngModelOptions]="{ standalone: true }"
                                            [options]="warehouses"
                                            [showToggleAll]="true"
                                            (onChange)="
                                                onItemSelectEstablishments(
                                                    $event.value
                                                )
                                            "
                                            [(ngModel)]="
                                                establishmentsPuntosDeVenta
                                            "
                                            [metaKeySelection]="true"
                                            [checkbox]="true"
                                            [filter]="true"
                                            [multiple]="true"
                                            optionLabel="name"
                                            [listStyle]="{ 'max-height': '120px' }"
                                            [style]="{ width: '25rem' }"
                                        >
                                            <ng-template
                                                let-establishment
                                                pTemplate="item"
                                            >
                                                <div class="country-item">
                                                    <div>
                                                        {{ establishment.name }}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-listbox>
                                    </div>
                                    <br />
                                    <div class="col">
                                        {{ numberOfEstablishments }} Puntos de Venta
                                        seleccionados.
                                    </div>
                                </div>
                                <div
                                    *ngIf="warehouses.length === 0"
                                    class="card-not-system"
                                >
                                    <i class="pi pi-info-circle"></i> No hay
                                    establecimientos cargados en la base de datos
                                </div>
                            </div>
                        </p-tabPanel> -->
                </p-tabView>
            </div>
            <div class="card">
                <form [formGroup]="userForm">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="dni" class="mr-2"><strong>Identificación:
                                            </strong></label>
                                        <input id="dni" readonly type="text" maxlength="40"
                                            oninput="this.value=this.value.slice(0,this.maxLength)" style="border: 0"
                                            formControlName="dni" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="fullName" class="mr-2"><strong>Nombre Completo:
                                            </strong></label>
                                        <input id="fullName" readonly type="text" maxlength="100" autocomplete="off"
                                            oninput="this.value=this.value.slice(0,this.maxLength)" style="border: 0"
                                            formControlName="fullName" size="35" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="phone" class="mr-2"><strong>Teléfono: </strong></label>
                                        <input id="phone" readonly type="text" appInputNumberAndMiddledash
                                            oninput="this.value=this.value.slice(0,this.maxLength)" style="border: 0"
                                            formControlName="phone" size="10" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <div class="form-group">
                                        <label for="email" class="mr-2">
                                            <strong>Correo: </strong>
                                        </label>
                                        <input id="email" readonly type="text" maxlength="100" autocomplete="off"
                                            oninput="this.value=this.value.slice(0,this.maxLength)" style="border: 0"
                                            formControlName="email" size="35" autocomplete="off" />
                                        <mat-error *ngIf="
                                                    userForm.controls.email.errors
                                                        ?.required
                                                ">
                                            Campo <strong>requerido.</strong>
                                        </mat-error>
                                        <mat-error *ngIf="
                                                    userForm.controls.email.errors
                                                        ?.pattern
                                                ">
                                            Correo <strong>no válido.</strong>
                                        </mat-error>
                                        <mat-error *ngIf="
                                                    userForm.controls.email.errors
                                                        ?.valid_domain
                                                ">
                                            Dominio <strong>no válido.</strong>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="
                                                d-flex
                                                justify-content-center
                                                mt-4
                                            ">
                                        <mat-checkbox class="check" formControlName="active">
                                            Activo
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="userName"><strong>Usuario: </strong></label>
                                        <input id="userName" type="text" class="form-control" formControlName="userName"
                                            autocomplete="off" />
                                        <mat-error *ngIf="
                                                    userForm.controls.userName
                                                        .errors?.required
                                                ">
                                            Campo <strong>requerido.</strong>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="pin"><strong>Pin: </strong></label>
                                        <input id="pin" type="text" class="form-control" formControlName="pin"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field *ngIf="!selectedUser?.id" style="margin-left: 10px !important">
                                        <input class="input" matInput placeholder="Contraseña" maxlength="100" [type]="
                                                    visiblePassword
                                                        ? 'password'
                                                        : 'text'
                                                " formControlName="password" />
                                        <mat-icon matSuffix (click)="
                                                    visiblePassword =
                                                        !visiblePassword
                                                ">
                                            {{
                                                    visiblePassword
                                                        ? 'visibility_off'
                                                        : 'visibility'
                                                }}
                                        </mat-icon>
                                        <mat-error *ngIf="
                                                    userForm.controls.password
                                                        ?.errors?.required
                                                ">
                                            Campo <strong>requerido.</strong>
                                        </mat-error>
                                        <mat-error *ngIf="
                                                    userForm.controls.password
                                                        ?.errors?.invalidPassword
                                                ">
                                            <strong>Al menos 8 caracteres, una
                                                letra mayúscula, una letra
                                                minúscula, un número y un
                                                carácter especial.</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col">
            <div class="card" style="height: 516px">
                <div class="row">
                    <div class="col-5">
                        <label for="sys"><strong>Seleccione Sistema: </strong></label>
                        <div class="list-group" id="list-tab" role="tablist" *ngFor="let sys of systems">
                            <a class="list-group-item list-group-item-action" id="{{ sys.id }}" data-toggle="list"
                                (click)="setRolesBySystem(sys)" href="#{{ sys.id }}" role="tab"
                                aria-controls="{{ sys.name }}">{{ sys.name }}</a>
                        </div>
                    </div>
                    <div class="col-7">
                        <label *ngIf="selectedSystem?.id" for="rol"><strong>
                                Roles para {{ systemName }}:</strong></label>
                        <div class="tab-content" id="nav-tabContent" *ngFor="let sys of systems">
                            <div class="tab-pane fade" id="w-{{ sys.id }}" role="tabpanel"
                                aria-labelledby="{{ sys.id }}">
                                <p-listbox *ngIf="roles.length > 0" [ngModelOptions]="{ standalone: true }"
                                    [options]="roles" [showToggleAll]="false" (onClick)="
                                            setListPermissionByRoles($event.value)
                                        " [(ngModel)]="rolesSelected" [metaKeySelection]="false" [checkbox]="true"
                                    [filter]="true" [multiple]="true" optionLabel="name"
                                    [listStyle]="{ 'max-height': '150px' }">
                                    <ng-template let-rol pTemplate="item">
                                        <div class="rol-item">
                                            <div pTooltip="{{
                                                        currentPermissionsByRol(rol)
                                                    }}">
                                                {{ rol.name }}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                                <div *ngIf="roles.length === 0" class="card-not-system">
                                    <i class="pi pi-info-circle"></i> Este
                                    sistema no tiene roles
                                </div>
                            </div>
                        </div>
                        <br />
                        <label> <strong>Mis permisos:</strong></label>
                        <p-tree [value]="treePermissions" [style]="{ 'font-size': '12px' }"
                            emptyMessage="No hay roles seleccionados" [virtualScroll]="true" [virtualNodeHeight]="5"
                            scrollHeight="{{
                                    rolesSelected.length === 0 ? '0px' : '185px'
                                }}">
                        </p-tree>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="ml-3 mr-3">
                <div class="row right">
                    <div class="col">
                        <button type="submit" pButton pRipple (click)="validateUser()"
                            label="{{ isNew ? 'Guardar' : 'Actualizar' }}" icon="pi pi-save"
                            class="p-button-info m-r-5"></button>
                    </div>
                    <div class="col">
                        <button pButton pRipple type="button" (click)="closeView()" label="Cancelar" icon="pi pi-times"
                            class=""></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </fieldset> -->
