import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { UserLogsPresenter } from './presenter/user-logs.presenter';
import { MessageService } from 'primeng/api';
import { UserInput } from 'src/app/model.ts/user.model';
import { UserLog } from 'src/app/model.ts/user-log.model';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss']
})
export class UserLogsComponent extends AbstractView implements OnInit {

  value = '';
  user: UserInput = {};

  usersLogs = [];
  page = 0;
  size = 25;
  totalElements = 0;
  selectedUser = '';
  pageSince = 1;
  pageUntil = 50;
  elements: any[];
  constructor(
    public router: Router,
    public dialogService: DialogService,
    private userLogsPresenter: UserLogsPresenter,
    private messageService: MessageService,
    public config: DynamicDialogConfig
  ) {
    super(messageService, router);
    userLogsPresenter.view = this;
    this.user = config.data.user;
    this.selectedUser = this.user.id;
    this.elements = [50, 100, 200, 500];
  }

  ngOnInit(): void {
    this.listUsersLogs();
  }

  listUsersLogs() {
    this.userLogsPresenter.listUsersLogs();
  }

  paginate($event) {
    this.page = $event['page'];
    if (this.page === 0) {
      this.pageSince = 1;
      this.pageUntil = this.size;
    } else {
      this.pageSince = this.size * this.page + 1;
      this.pageUntil = this.size * (this.page + 1);
    }
    if (this.usersLogs.length > 0) {
      this.listUsersLogs();
    }
  }

}
