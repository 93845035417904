import { QuotationsMenuComponent } from './components/menu-components/quotations-menu/quotations-menu.component';
import { EllipsisPipe } from './modules/shared/pipe/ellipsis.pipe';
import {
    CommonModule,
    LocationStrategy,
    PathLocationStrategy,
    registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEc from '@angular/common/locales/es-US';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { AppRoutingModule } from './app-routing.module';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { MenuService } from './app.menu.service';
import { AppSidebartabcontentComponent } from './app.sidebartabcontent.component';
import { AppConfigComponent } from './components/config/app.config.component';
import { AppFooterComponent } from './components/footer/app.footer.component';
import { AppMainComponent } from './components/main/app.main.component';
import { EstablishmentMenuComponent } from './components/menu-components/establishment-menu/establishment.menu.component';
import { PriceMenuComponent } from './components/menu-components/price-menu/price-menu.component';
import { SupplyingMenuComponent } from './components/menu-components/supplying-menu/supplying-menu.component';
import { UserMenuComponent } from './components/menu-components/user-menu/user-menu.component';
import { ConfigurationMenuComponent } from './components/menu-components/configuration-menu/configuration.menu.component';
import { AppMenuitemComponent } from './components/menu-item/app.menuitem.component';
import { AppSideBarComponent } from './components/side-bar/app.sidebar.component';
import { AppTopbarComponent } from './components/top-bar/app.topbar.component';
import { AppTopbarPresenter } from './components/top-bar/presenter/app.topbar.presenter';
import { AuthGuard } from './core/auth.guard';
import { HeaderInterceptor } from './core/auth.interceptor';
import { GraphQLModule } from './graphql.module';
import { PrimeModule } from './modules/prime/prime.module';
import { UserManageModule } from './modules/user-manage/user-manage.module';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { ReportMenuComponent } from './components/menu-components/report-menu/report-menu.component';
import { ProductMenuComponent } from './components/menu-components/product-menu/product-menu.component';
import { PricePdvMenuComponent } from './components/menu-components/price-pdv-menu/price-pdv-menu.component';
// import { BankPresenter } from
// './modules/configuration-manage/banks/presenter/bank.presenter';
// import { EstablishmentPresenter } from './modules/configuration-manage/banks/presenter/establishment.presenter';

// Application Components
// Demo pages
// Demo services
registerLocaleData(localeEc, 'es-US');

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppCodeModule,
        PrimeModule,
        GraphQLModule,
        BlockUIModule.forRoot(),
        UserManageModule,
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        EstablishmentMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppSidebartabcontentComponent,
        AppLoginComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppSideBarComponent,
        PriceMenuComponent,
        UserMenuComponent,
        SupplyingMenuComponent,
        QuotationsMenuComponent,
        ConfigurationMenuComponent,
        ReportMenuComponent,
        ProductMenuComponent,
        PricePdvMenuComponent
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        MenuService,
        DialogService,
        ConfirmationService,
        { provide: LOCALE_ID, useValue: 'es-US' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true,
        },
        AppTopbarPresenter,
        MessageService,
        AuthGuard,
        // BankPresenter,
        // EstablishmentPresenter
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
