import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Person } from 'src/app/model.ts/person.model';

import { GeneratePersonComponent } from '../generate-person/generate-person.component';
import { GenerateImportPersonView } from './generate-import-person.view';
import { GenerateImportPersonPresenter } from './presenter/generate-import-person.presenter';

@Component({
    selector: 'app-generate-import-person',
    templateUrl: './generate-import-person.component.html',
    styleUrls: ['./generate-import-person.component.scss'],
})
export class GenerateImportPersonComponent
    extends AbstractView
    implements OnInit, GenerateImportPersonView
{
    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        public dialogService: DialogService,
        private messageService: MessageService,
        private generateImportPersonPresenter: GenerateImportPersonPresenter
    ) {
        super(messageService, router);
        generateImportPersonPresenter.view = this;
    }
    value = '';
    persons: Person[] = [];
    selectedPerson: Person;
    autoCompleteValue = 0;
    numberResultsPersons: number;

    ngOnInit(): void {
        this.setInitPersonsOfLDAP();
    }

    setInitPersonsOfLDAP() {
        this.value = '';
        this.generateImportPersonPresenter.getPersonsByValueLDAP();
    }

    addPerson() {
        if (typeof this.selectedPerson !== 'string') {
            if (this.selectedPerson) {
                if (this.selectedPerson.user?.id) {
                    if (this.selectedPerson.user.active) {
                        this.showInfo(
                            'Información',
                            'Este usuario ya está importado'
                        );
                    } else {
                        this.showInfo(
                            'Información',
                            'Este usuario está inactivo'
                        );
                    }
                    this.selectedPerson = null;
                    this.autoCompleteValue = 0;
                    return;
                } else {
                    this.showGeneratePersonComponent('Importar Persona');
                }
                this.autoCompleteValue = 0;
            }
        }
    }

    showGeneratePersonComponent(title: string) {
        const refNew = this.ref;
        this.ref = this.dialogService.open(GeneratePersonComponent, {
            header: title,
            width: '35%',
            height: 'auto',
            data: { person: this.selectedPerson, isImport: true },
        });
        refNew.close();

        this.ref.onClose.subscribe(() => {
            this.generateImportPersonPresenter.personService.isUpdateListPersons =
                true;
        });
    }

    onSearchPerson(event) {
        this.value = event.query;
        this.generateImportPersonPresenter.getPersonsByValueLDAP();
    }

    omit_special_char(event) {
        let k;
        k = event.charCode; // k = event.keyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k === 8 ||
            k === 32 ||
            (k >= 48 && k <= 57)
        );
    }

    onKey(event) {
        this.autoCompleteValue = event.target.value.length;
    }
}
