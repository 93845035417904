import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { AbstractView } from 'src/app/core/abstract_view';
import { Person } from 'src/app/model.ts/person.model';

import { GenerateImportPersonComponent } from '../generate-import-person/generate-import-person.component';
import { GeneratePersonComponent } from '../generate-person/generate-person.component';
import { PersonsView } from './persons.view';
import { PersonsPresenter } from './presenter/persons.presenter';

@Component({
    selector: 'app-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss'],
})
export class PersonsComponent
    extends AbstractView
    implements OnInit, AfterViewInit, AfterViewChecked, PersonsView
{
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    persons: Person[] = [];
    selectedPerson: Person;
    numberPersons = 0;
    value = '';
    public ref;
    activePerson = true;

    totalElements = 0;
    totalPages = 0;
    page = 0;
    size = 50;
    pageSince = 1;
    pageUntil = 50;
    elements = [50, 100, 200, 500];

    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public dialogService: DialogService,
        private messageService: MessageService,
        private personsPresenter: PersonsPresenter
    ) {
        super(messageService, router);
        personsPresenter.view = this;

        if (!this.getAuth(['MANAGE_USERS'])) {
            this.showError('', 'Sin autorización');
            this.redirectTo('main');
        }
    }
    ngOnInit(): void {
        this.listPersons();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchPerson();
    }

    setFocusSearchPerson() {
        /* setTimeout(() =>
            this.renderer.selectRootElement('#searchPersona').focus()
        ); */
    }

    ngAfterViewChecked(): void {
        // check if generate-person was imported a person
        if (this.personsPresenter.personService.isUpdateListPersons) {
            this.verifyStatusPerson();
            this.personsPresenter.personService.isUpdateListPersons = false;
        }
    }

    verifyStatusPerson() {
        if (this.activePerson) {
            this.listActivePersons();
        } else {
            this.listInactivePersons();
        }
        this.personsPresenter.getNumberPersons();
    }

    listActivePersons() {
        console.log('listActivePersons');
        this.personsPresenter.listPersons(true);
    }

    listInactivePersons() {
        this.personsPresenter.listPersons(false);
    }

    listPersons() {
        this.verifyStatusPerson();
    }

    clickActivePersonChecked() {
        this.verifyStatusPerson();
    }

    allPersons() {
        this.value = '';
        this.verifyStatusPerson();
    }

    /* onSearchChange(value) {
        setTimeout(() => {
            this.reset();
            this.value = value.target.value;
            this.verifyStatusPerson();
        }, 2000);
    } */

    onSearchChangeAu(value) {
        this.value = value.query;
        this.verifyStatusPerson();
        this.reset();
    }

    openGenerateImportPersonComponent() {
        this.showGenerateImportPersonComponent('Importar persona');
    }

    showGenerateImportPersonComponent(title: string) {
        this.ref = this.dialogService.open(GenerateImportPersonComponent, {
            header: title,
            closeOnEscape: true,
            width: '50%',
            height: 'auto',
            contentStyle: { overflow: 'visible' },
        });
        this.ref.onClose.subscribe(() => {
            this.verifyStatusPerson();
        });
    }

    editGeneratePersonComponent(person: Person) {
        this.selectedPerson = person;
        this.showGeneratePersonComponent('Editar Persona', false);
        // aqui colocar el siguiente servicio
        //   this.personsPresenter.personById(person.id);
    }

    showGeneratePersonComponent(title: string, isImport: boolean) {
        this.ref = this.dialogService.open(GeneratePersonComponent, {
            header: title,
            closeOnEscape: true,
            width: '35%',
            height: 'auto',
            contentStyle: { overflow: 'visible' },
            data: { person: this.selectedPerson.id, isImport },
        });
        this.ref.onClose.subscribe(() => {
            this.verifyStatusPerson();
        });
    }

    traslatorIdentificationType(type: string) {
        if (type === 'DNI') {
            return 'Cédula';
        }
        if (type === 'RUC') {
            return 'RUC';
        }
        if (type === 'PASSPORT') {
            return 'Pasaporte';
        }
    }

    reset() {
        this.page = 0;
        this.pageSince = 1;
        this.paginator.changePage(0);
    }

    paginate($event) {
        this.page = $event['page'];
        if (this.page === 0) {
            this.pageSince = 1;
            this.pageUntil = this.size;
        } else {
            this.pageSince = this.size * this.page + 1;
            this.pageUntil = this.size * (this.page + 1);
        }
        this.listPersons();
    }
}
