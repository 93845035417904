<p-toast></p-toast>
<div class="continer-card">
    <div class="card card-botonera">
        <div class="row justify-content-end">
            <div class="pr-5">
                <div class="row">
                    <div class="pr-3 pt-2">
                        <h5>Ver {{numberPersons}} personas {{activePerson ?'Inactivas':'Activas'}}</h5>
                    </div>
                    <div class="pt-2">
                        <p-inputSwitch [(ngModel)]="activePerson" (onChange)="clickActivePersonChecked()">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>
            <div class="pr-3">
                <!-- <span class="p-input-icon-right">
                    <i style="cursor: pointer;" class="pi pi-times-circle" (click)="allPersons()"></i>
                    <input id="searchPersona" style="height: 35px;" type="text" autocomplete="off"
                        (onClear)="allPersons()" pInputText placeholder="Buscar persona" name="selectWarehouse"
                        [(ngModel)]="value" (keyup)="onSearchChange($event)"
                        pTooltip="Buscar por nombre o identificación" tooltipPosition="top" />
                </span> -->
                <p-autoComplete (onClear)="allPersons()" [delay]="2000" placeholder="Buscar persona"
                    (completeMethod)="onSearchChangeAu($event)" [type]="'search'">
                </p-autoComplete>
            </div>
        </div>
    </div>
    <p-table #dt [value]="persons" styleClass="p-datatable-gridlines">
        <ng-template class="around" pTemplate="header">
            <tr>
                <th scope="col" [ngStyle]="{'width': '13%','text-align':'center'}">Usuario LDAP</th>
                <th scope="col" [ngStyle]="{'width': '23%','text-align':'center'}">Nombre</th>
                <th scope="col" [ngStyle]="{'width': '10%','text-align':'center'}">Credencial </th>
                <th scope="col" [ngStyle]="{'width': '10%','text-align':'center'}">Identificación </th>
                <th scope="col" [ngStyle]="{'width': '23%','text-align':'center'}">Correo </th>
                <th scope="col" [ngStyle]="{'width': '11%','text-align':'center'}">Teléfono </th>
                <th scope="col" [ngStyle]="{'width': '10%','text-align':'center'}">Acción </th>
            </tr>
        </ng-template>
        <ng-template class="around" pTemplate="body" let-person>
            <tr>
                <td>{{person.userName}}</td>
                <td>{{person.fullName}}</td>
                <td class="elli text-center">{{traslatorIdentificationType(person.identificationType)}}</td>
                <td class="elli text-center">{{person.dni}}</td>
                <td class="elli">{{person.email }}</td>
                <td class="elli text-center">{{person.phone}}</td>
                <td [ngStyle]="{'text-align':'center'}">
                    <i class="material-icons color-icon-primary" type="button" pTooltip="Editar" tooltipPosition="top"
                        (click)="editGeneratePersonComponent(person)">drive_file_rename_outline
                    </i>
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Existen {{totalElements ? totalElements : 0 }} personas {{activePerson ?'Activas':'Inactivas'}}
            </div>
        </ng-template> -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No se ha encontrado registros.</td>
            </tr>
        </ng-template>
    </p-table>

    <div class="row p-justify-center">
        <span class="p-paginator-current ng-star-inserted">Registros {{ pageSince }} de {{ pageUntil }} del total
            {{ totalElements }}</span>
        <p-paginator #paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)">
        </p-paginator>
        <p-dropdown class="pt-3 dp-paginate" [options]="elements" [(ngModel)]="size" [showClear]="false"
            (onChange)="listPersons()"></p-dropdown>
    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>
