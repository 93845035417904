import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
@Component({
    selector: 'app-establishment-menu',
    templateUrl: './establishment.menu.component.html',
})
export class EstablishmentMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit() {
        if (this.getAuth(['MANAGE_ESTABLISHMENTS'])) {
            this.model.push(
                {
                    label: 'Directorio',
                    icon: 'pi pi-fw pi-home',
                    routerLink: ['/establishment/establishments'],
                },
                {
                    label: 'Almacenes',
                    icon: 'pi pi-fw pi-building',
                    routerLink: ['/warehouses'],
                }
            );
        }
        if (this.getAuth(['MANAGE_CPOS'])) {
            this.model.push({
                label: 'Configuración CPOS',
                icon: 'pi pi-fw pi-print',
                routerLink: ['/megapos-configuration/configuration-cpos'],
            });
        }
        if (this.getAuth(['MANAGE_COBROS_PAGOS'])) {
            this.model.push({
                label: 'Registro PDV (TU ENLACE)',
                icon: 'pi pi-fw pi-link',
                routerLink: ['/megapos-configuration/auth-tuenlace'],
            });
        }
        if (this.getAuth(['MANAGE_SYNC_PDV'])) {
            this.model.push({
                label: 'Sincronizar PDVS',
                icon: 'pi pi-fw pi-ticket',
                routerLink: ['/megapos-configuration/sequential'],
            });
            /*
            {
                 label: 'Configuración de Megapos',
                 icon: 'pi pi-fw pi-cog',
                 routerLink: ['/megapos/functional-config']
             },
             */
        }
    }
}
