import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Rol, System, SystemInput } from 'src/app/model.ts/rol.model';

import { GenerateSystemView } from './generate-system.view';
import { GenerateSystemPresenter } from './presenter/generate-system.presenter';

@Component({
    selector: 'app-generate-system',
    templateUrl: './generate-system.component.html',
    styleUrls: ['./generate-system.component.scss'],
})
export class GenerateSystemComponent
    extends AbstractView
    implements OnInit, AfterViewInit, GenerateSystemView
{
    isNew: boolean;
    systemForm = this.formBuilder.group({});
    selectedSystem: System;
    roles: Rol[] = [];
    rolesSelected: Rol[] = [];

    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public ref: DynamicDialogRef,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private generateSystemPresenter: GenerateSystemPresenter
    ) {
        super(messageService, router);
        generateSystemPresenter.view = this;
        this.isNew = config.data.isNew;
        this.selectedSystem = config.data.system;
    }

    ngOnInit(): void {
        if (this.isNew) {
            this.setNewSystemToForm();
        } else {
            this.setSystemToForm();
        }
    }

    ngAfterViewInit(): void {
        this.setFocusName();
    }

    setFocusName() {
        setTimeout(() => this.renderer.selectRootElement('#name').focus());
    }

    setSystemToForm() {
        this.systemForm = this.formBuilder.group({
            name: new FormControl(this.selectedSystem.name, [
                Validators.required,
            ]),
            code: new FormControl(this.selectedSystem.code, [
                Validators.required,
            ]),
            active: new FormControl(this.selectedSystem.active, [
                Validators.required,
            ]),
        });
    }

    setNewSystemToForm() {
        this.systemForm = this.formBuilder.group({
            name: new FormControl('', [Validators.required]),
            code: new FormControl('', [Validators.required]),
            active: new FormControl(true),
        });
    }

    validateSystem() {
        this.markFormGroupTouched(this.systemForm);

        if (this.systemForm.invalid) {
            this.showMessage('Información incompleta');
        } else {
            this.setSystem();
        }
    }

    /* Marks all controls in a form group as touched
       @param formGroup - The form group to touch    */
    private markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    setSystem() {
        let system: SystemInput = {
            id: this.selectedSystem.id ? this.selectedSystem.id : null,
            name: this.systemForm.get('name').value.toUpperCase(),
            code: this.systemForm.get('code').value.toUpperCase(),
            active: this.systemForm.get('active').value,
        };
        system = JSON.parse(JSON.stringify(system), this.omitTypename);
        this.showConfirmation(system);
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    closeView() {
        this.ref.close();
    }

    showConfirmation(system: SystemInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${this.isNew ? 'guardar' : 'actualizar'} el sistema?`,
            header: `Confirme ${this.isNew ? 'acción' : 'actualización'}`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.generateSystemPresenter.saveSystem(system);
            },
        });
    }
}
