import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
    Person,
    PersonInput,
    IdentificationType,
} from 'src/app/model.ts/person.model';
import { TypeIdentification } from 'src/app/model.ts/person.model';
import { validateCedula } from 'src/app/modules/shared/directive/cedula';
import { validateDomain } from 'src/app/modules/shared/directive/domain';
import { validatePassport } from 'src/app/modules/shared/directive/passport';
import { validatePhone } from 'src/app/modules/shared/directive/phone';
import { validateRuc } from 'src/app/modules/shared/directive/ruc';

import { AbstractView } from '../../../../core/abstract_view';
import { GeneratePersonView } from './generate-person.view';
import { GeneratePersonPresenter } from './presenter/generate-person.presenter';

@Component({
    selector: 'app-generate-person',
    templateUrl: './generate-person.component.html',
    styleUrls: ['./generate-person.component.scss'],
})
export class GeneratePersonComponent
    extends AbstractView
    implements OnInit, GeneratePersonView
{
    numberResultsPersons: number;

    persons: Person[];
    personForm = this.formBuilder.group({});
    selectedPerson: Person;
    showSelectedPerson = false;
    searchPersonVisible = false;
    value = '';

    listIdentifications: TypeIdentification[] = [];
    autoCompleteValue = 0;
    isImport;
    errorLengthFullName = false;

    patronEmail: string | RegExp =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    patronPhone: string | RegExp = /^0([2-7]{1}[0-9]{7}|[8-9]{1}[0-9]{8})$/;

    mapForm = new Map();

    id = '';

    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private generatePersonPresenter: GeneratePersonPresenter
    ) {
        super(messageService, router);
        generatePersonPresenter.view = this;
        this.id = config.data.person;
        this.isImport = config.data.isImport;
        this.personForm = this.formBuilder.group({
            type: new FormControl({ value: '', disabled: true }, [
                Validators.required,
            ]),
            dni: new FormControl({ value: '', disabled: true }, [
                Validators.required,
            ]),
            fullName: new FormControl({ value: '', disabled: true }, [
                Validators.required,
            ]),
            userName: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required, validatePhone()]),
            email: new FormControl('', [
                Validators.required,
                Validators.pattern(this.patronEmail),
                validateDomain(),
            ]),
            active: new FormControl(null),
        });
    }

    ngOnInit(): void {
        this.personById(this.id);
        this.builMapForm();
    }

    personById(id) {
        this.generatePersonPresenter.personById(id);
    }

    builMapForm() {
        this.mapForm.set('dni', 'Identificación');
        this.mapForm.set('fullName', 'Nombre Completo');
        this.mapForm.set('phone', 'Teléfono');
        this.mapForm.set('email', 'Correo');
    }

    setPersonToForm() {
        this.personForm = this.formBuilder.group({
            type: new FormControl(this.setIdentificationType(), [
                Validators.required,
            ]),
            dni: new FormControl(this.selectedPerson.dni, [
                Validators.required,
            ]),
            fullName: new FormControl(this.selectedPerson.fullName, [
                Validators.required,
            ]),
            userName: new FormControl(this.selectedPerson.userName, [
                Validators.required,
            ]),
            phone: new FormControl(this.selectedPerson.phone, [
                Validators.required,
                validatePhone(),
            ]),
            email: new FormControl(this.selectedPerson.email, [
                Validators.required,
                Validators.pattern(this.patronEmail),
                validateDomain(),
            ]),
            active: new FormControl(
                this.isImport ? true : this.selectedPerson.active
            ),
        });

        this.verifyValueIdentificationType();
    }

    verifyValueIdentificationType() {
        if (this.personForm.get('type').value) {
            this.personForm.get('type').disable();
        }
    }

    buildIdentificationTypeArray() {
        this.listIdentifications = Object.keys(IdentificationType).map(
            (key) => ({ name: IdentificationType[key], id: key })
        );
    }

    buildNewIdentificationTypeArray() {
        this.listIdentifications = [{ id: 'PASSPORT', name: 'PASAPORTE' }];
    }

    validateTypeDniWithDni(dni: string): IdentificationType {
        if (dni) {
            if (validateRuc(dni)) {
                return IdentificationType.RUC;
            } else {
                if (validateCedula(dni)) {
                    return IdentificationType.DNI;
                } else {
                    if (validatePassport(dni)) {
                        return null;
                    } else {
                        this.listIdentifications = [];
                    }
                }
            }
        }
    }

    validateDni(dni: string): IdentificationType {
        if (dni) {
            if (validateRuc(dni)) {
                return IdentificationType.RUC;
            } else {
                if (validateCedula(dni)) {
                    return IdentificationType.DNI;
                } else {
                    return IdentificationType.PASSPORT;
                }
            }
        }
    }
    setIdentificationType() {
        if (this.selectedPerson.identificationType) {
            return this.listIdentifications.find(
                (item: TypeIdentification) =>
                    item.id === this.selectedPerson.identificationType
            );
        } else {
            const verifyIdentification = this.validateTypeDniWithDni(
                this.selectedPerson.dni
            );

            if (verifyIdentification) {
                return this.listIdentifications.find(
                    (item: TypeIdentification) =>
                        item.name === verifyIdentification
                );
            } else {
                if (this.listIdentifications.length !== 0) {
                    this.buildNewIdentificationTypeArray();
                }
                return null;
            }
        }
    }

    showConfirmation(person: PersonInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${
                this.isImport ? 'guardar' : 'actualizar'
            } la persona?`,
            header: `Confirme ${this.isImport ? 'acción' : 'actualización'}`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.generatePersonPresenter.savePerson(person);
            },
        });
    }

    closeView() {
        this.ref.close();
    }

    compareFn(c1: TypeIdentification, c2: TypeIdentification): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    onSearchChangeDNI(searchValue: string): void {
        if (searchValue.length < 4) {
            this.personForm.controls['type'].setValue(null);
        } else {
            this.buildIdentificationTypeArray();

            const verifyIdentification = this.validateDni(searchValue);

            const typeDNI = this.listIdentifications.find(
                (item: TypeIdentification) => item.name === verifyIdentification
            );
            this.personForm.controls['type'].setValue(typeDNI);
        }
        this.personForm.get('type').disable();
    }

    validatePerson() {
        if (this.personForm.invalid) {
            this.showMessage(
                'Información incompleta en ' + this.findInvalidControls()
            );
        } else {
            const idType = this.personForm.get('type').value;

            const person: PersonInput = {
                id: this.selectedPerson.id,
                fullName: this.personForm.get('fullName').value,
                userName: this.personForm.get('userName').value,
                dni: this.personForm.get('dni').value,
                email: this.personForm.get('email').value,
                active: this.personForm.get('active').value,
                phone: this.personForm.get('phone').value,
                identificationType: idType.id,
                referenceId: this.selectedPerson.referenceId,
            };

            this.showConfirmation(person);
        }
    }

    findInvalidControls() {
        const invalid = [];
        const controls = this.personForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(this.mapForm.get(name));
            }
        }
        return invalid;
    }
}
