import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { PermissionInput, Rol, RolInput, System, SystemInput } from 'src/app/model.ts/rol.model';

import { GenerateRolComponent } from '../generate-rol/generate-rol.component';
import { RolesPresenter } from './presenter/roles.presenter';
import { RolesView } from './roles.view';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss'],
})
export class RolesComponent
    extends AbstractView
    implements OnInit, AfterViewInit, RolesView
{
    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public dialogService: DialogService,
        private rolesPresenter: RolesPresenter,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {
        super(messageService, router);
        rolesPresenter.view = this;

        if (!this.getAuth(['MANAGE_USERS'])) {
            this.showError('', 'Sin autorización');
            this.redirectTo('main');
        }
    }

    roles: Rol[] = [];
    selectedRol: Rol = {};

    page = 0;
    size = 12;
    totalElements = 0;
    value = '';
    activeRol = true;
    activeRoles = true;
    public ref;
    systems: System[];
    selectedSystem: System;

    numberRoles = 0;

    systemAll: System = {
        id: null,
        name: 'TODOS',
        active: true,
        code: 'all',
    };

    ngOnInit(): void {
        this.selectedSystem = this.systemAll;
        this.listSystems();
        this.listRoles();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchRoles();
    }

    setFocusSearchRoles() {
        setTimeout(() => this.renderer.selectRootElement('#searchRol').focus());
    }

    clickActiveRolChecked() {
        this.listRoles();
    }

    listSystems() {
        this.rolesPresenter.listActiveSystem();
    }

    openGenerateRolComponent() {
        this.selectedRol = {};
        this.showGenerateRolComponent(
            'Nuevo Rol',
            true,
            this.selectedSystem.id ? this.selectedSystem : null
        );
    }
    editGenerateRolComponent(rol: Rol) {
        this.rolesPresenter.rolById(rol.id);
    }

    changeRolActive(rol: Rol) {
        this.selectedRol = rol;
        this.showChangeActivation();
    }

    showChangeActivation() {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${
                this.activeRol ? 'inactivar' : 'activar'
            } el rol?`,
            header: `Confirme ${
                this.activeRol ? 'inactivación' : 'activación'
            }`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.changeActiveRolInput();
            },
            reject: () => {
                this.discardChangesActiveRol();
            },
        });
    }

    discardChangesActiveRol() {
        this.selectedRol.active = this.selectedRol.active ? false : true;
    }

    changeActiveRolInput() {
        let permissions: PermissionInput[] = [];
        this.selectedRol.permissions.forEach((permission) => {
            const systemInput: SystemInput = {
                id: this.selectedRol.system.id,
                name: this.selectedRol.system.name,
                code: this.selectedRol.system.code,
                active: this.selectedRol.system.active,
            };
            const permissionInput: PermissionInput = {
                id: permission.id,
                name: permission.name,
                domainAction: permission.domainAction,
                system: systemInput,
                active: permission.active,
            };
            permissions.push(permissionInput);
        });

        permissions = JSON.parse(
            JSON.stringify(permissions),
            this.omitTypename
        );

        const system: SystemInput = {
            id: this.selectedRol.system.id,
            name: this.selectedRol.system.name,
            code: this.selectedRol.system.code,
            active: this.selectedRol.system.active,
        };

        const rolInput: RolInput = {
            id: this.selectedRol.id,
            name: this.selectedRol.name,
            permissions,
            system,
            active: this.selectedRol.active,
        };

        this.rolesPresenter.saveRol(rolInput);
    }
    showGenerateRolComponent(title: string, isNew: boolean, system: System) {
        this.ref = this.dialogService.open(GenerateRolComponent, {
            header: title,
            closeOnEscape: true,
            width: '35%',
            height: 'auto',
            data: { rol: this.selectedRol, isNew, system },
        });
        this.ref.onClose.subscribe(() => {
            this.listRoles();
        });
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    paginate($event) {
        this.page = $event['page'];
        this.listRoles();
    }

    onSearchChange(searchValue: any): void {
        const searchLength = searchValue.target.value.length;
        // if (searchValue.keyCode === 13) {
        if (searchLength >= 3 || searchLength === 0) {
            this.value = searchValue.target.value;
            this.value = this.value.toUpperCase();
            this.listRoles();
        }
        // }
    }

    listRoles(): void {
        if (this.selectedSystem.code === 'all') {
            this.rolesPresenter.listRoles();
            this.rolesPresenter.getNumberRoles();
        } else {
            this.rolesPresenter.listRolesBySystemId();
            this.rolesPresenter.getNumberRolesBySystemId();
        }
    }

    currentPermissionsByRol(rol: Rol) {
        let permissions = '';
        rol.permissions.forEach((r) => {
            permissions = permissions + '- ' + r.name + '\n';
        });

        if (permissions === '') {
            permissions = 'Sin permisos asignados';
        }

        return permissions;
    }
}
