import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { RolInput } from 'src/app/model.ts/rol.model';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { RolesView } from '../roles.view';

@Injectable()
export class RolesPresenter implements Presenter {
    view: RolesView;

    constructor(
        private usersRolesPermissionsService: UsersRolesPermissionsService,
        private systemsRolesPermissionsService: SystemsRolesPermissionsService
    ) {}

    listRoles() {
        this.view.blockUi();
        this.usersRolesPermissionsService
            .listRolesByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                this.view.activeRoles,
                this.view.selectedSystem?.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.roles = data.rol.data;
                        this.view.totalElements = data.rol.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen roles cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberRoles() {
        this.view.blockUi();
        this.usersRolesPermissionsService
            .listRolesByStatus(
                0,
                1000,
                '',
                !this.view.activeRoles,
                this.view.selectedSystem?.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberRoles = data.rol.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen roles cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberRolesBySystemId() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listRolesBySystemId(
                0,
                1000,
                !this.view.activeRoles,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    this.view.numberRoles = data.rolBySystem.size;
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listRolesBySystemId() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listRolesBySystemId(
                this.view.page,
                this.view.size,
                this.view.activeRoles,
                this.view.selectedSystem.id
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.roles = data.rolBySystem.data;
                        this.view.totalElements = data.rolBySystem.size;
                    } else {
                        this.view.roles = [];
                        this.view.totalElements = 0;
                        this.view.showWarn(
                            'Atención',
                            'No existen roles cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listActiveSystem() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(0, 10, '', true)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.systems = data.systems.data;
                        this.view.totalElements = data.systems.size;
                        this.view.systems.unshift(this.view.systemAll);
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    saveRol(rol: RolInput) {
        this.view.blockUi();
        this.usersRolesPermissionsService.saveRol(rol).subscribe(
            ({ data }) => {
                this.view.showSuccess('Rol', 'Guardado con éxito');
                this.view.listRoles();
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }

    rolById(rolId: string) {
        this.view.blockUi();
        this.usersRolesPermissionsService.rolById(rolId).subscribe(
            ({ data }) => {
                this.view.selectedRol = data.rolById;
                this.view.showGenerateRolComponent(
                    'Editar Rol ' + data.rolById.name,
                    false,
                    this.view.selectedRol.system
                );
                this.view.allowUi();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
