<p-toast></p-toast>
<div class="continer-card">
    <div class="card card-botonera">
        <div class="row justify-content-between">
            <div class="pl-3">
                <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button bc-primary p-mr-2"
                    (click)="openGenerateSystemComponent()"></button>
                <!-- <div class="ml-5 ">
                                    <button pButton pRipple label="Sincroniza Usuarios" icon="pi pi-refresh"
                                        class="p-button-primary p-mr-2" (click)="openGenerateSystemComponent()"></button>
                                </div> -->
            </div>
            <div class="pr-5">
                <div class="row">
                    <div class="pr-3 pt-2">
                        <h5>Ver {{numberSystems}} sistemas {{activeSystem ?'Inactivos':'Activos'}}</h5>
                    </div>
                    <div class="pr-3 pt-2">
                        <p-inputSwitch [(ngModel)]="activeSystem" (onChange)="clickActiveSystemChecked()">
                        </p-inputSwitch>
                    </div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input id="searchSystem" maxlength="20" style="height: 35px;" autofocus class="size-200"
                            autoHighlight="true" pInputText type="text" appInputTextAndNumber
                            (keyup)="onSearchChange($event)" placeholder="Buscar sistema..." />
                    </span>
                </div>
            </div>
        </div>
    </div>
    <p-table #dt [value]="systems" styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines">
        <ng-template pTemplate="header" pTemplate="header">
            <tr>
                <th scope="col" [ngStyle]="{'width': '30%','text-align':'center'}">Nombre</th>
                <th scope="col" [ngStyle]="{'width': '30%','text-align':'center'}">Código</th>
                <th scope="col" [ngStyle]="{'width': '20%','text-align':'center'}">Estado</th>
                <th scope="col" [ngStyle]="{'width': '20%'}" class="text-center">Acción</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" pTemplate="body" let-system>
            <tr>
                <td class="elli text-center">{{system.name|uppercase}}</td>
                <td class="elli text-center">{{system.code|uppercase}}</td>
                <td>
                    <div class="row">
                        <div class="col">
                            <div class="d-flex justify-content-center">
                                <p-checkbox pTooltip="Inactivar sistema" [style]="{cursor: 'pointer'}"
                                    (click)="changeSystemActive(system)" [(ngModel)]="system.active" binary="false">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="d-flex justify-content-center">
                        <div class="row">
                            <div class="col">
                                <i *ngIf="this.getAuth(['MANAGE_USERS'])" class="material-icons color-icon-primary"
                                    type="button" pTooltip="Editar" tooltipPosition="left"
                                    (click)="editGenerateSystemComponent(system)">drive_file_rename_outline
                                </i>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <!--  <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Existen {{totalElements ? totalElements : 0 }} sistemas {{activeSystem ?'Activos':'Inactivos'}}
            </div>
        </ng-template> -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">No se ha encontrado registros.</td>
            </tr>
        </ng-template>
    </p-table>
    <h2 class="center m-4 text-warning" *ngIf="totalElements==0">No hay resultados de su busqueda</h2>
    <p-paginator [rows]="size" [totalRecords]="totalElements" (onPageChange)="paginate($event)">
    </p-paginator>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
