import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { UserLogsView } from '../user-logs.view';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';


@Injectable()
export class UserLogsPresenter implements Presenter {
    view: UserLogsView;

    constructor(
        public usersRolesPermissionsService: UsersRolesPermissionsService
    ) { }

    listUsersLogs() {
        this.view.blockUi();
        this.usersRolesPermissionsService.
            listUsersLogs(
                this.view.page,
                this.view.size,
                this.view.selectedUser
            )
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.usersLogs = data.userLog.data;
                        this.view.totalElements = data.userLog.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen permisos cargados en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }
}
