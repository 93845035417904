import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { RolInput } from 'src/app/model.ts/rol.model';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';
import { UsersRolesPermissionsService } from 'src/app/services/users-roles-permissions.service';

import { GenerateRolView } from '../generate-rol.view';

@Injectable()
export class GenerateRolPresenter implements Presenter {
    view: GenerateRolView;

    constructor(
        private systemsRolesPermissionsService: SystemsRolesPermissionsService,
        private userRolesPermissions: UsersRolesPermissionsService
    ) {}

    listSystems() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listSystemsByStatus(0, 100, '', true)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.systems = data.systems.data;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen sistemas cargados en la base de datos'
                        );
                    }
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    listPermissionsBySystem() {
        this.view.blockUi();
        this.systemsRolesPermissionsService
            .listPermissionsBySystemId(true, this.view.selectedSystem.id)
            .subscribe(
                ({ data }) => {
                    this.view.permissions = data.permissionsBySystem;
                    this.view.orderRolesSelected(this.view.selectedPermissions);
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    saveRol(rol: RolInput) {
        this.view.blockUi();
        this.userRolesPermissions.saveRol(rol).subscribe(
            () => {
                this.view.showSuccess('', 'Rol guardado correctamente');
                this.view.closeView();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
