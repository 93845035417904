import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PersonService } from 'src/app/services/person.service';

import { GenerateImportPersonView } from '../generate-import-person.view';

@Injectable()
export class GenerateImportPersonPresenter implements Presenter {
    view: GenerateImportPersonView;

    constructor(public personService: PersonService) {}

    getPersonsByValueLDAP() {
        this.view.blockUi();
        this.personService.listPersonsByValueOfLDAP(this.view.value).subscribe(
            ({ data }) => {
                this.view.persons = data.findPersonsByValue;
                if (this.view.persons.length <= 0) {
                    this.view.showWarn('Personas', 'Búsqueda sin registro');
                    this.view.numberResultsPersons = 0;
                } else {
                    this.view.numberResultsPersons = this.view.persons.length;
                }
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
