import { User, UserInput } from './user.model';

export interface Person {
    id?: string;
    fullName?: string;
    dni?: string;
    email?: string;
    active?: boolean;
    referenceId?: string;
    phone?: string;
    identificationType?: IdentificationType;
    user?: User;
    userName?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
}
export interface PersonInput {
    id?: string;
    fullName?: string;
    dni?: string;
    email?: string;
    active?: boolean;
    referenceId?: string;
    phone?: string;
    identificationType?: IdentificationType;
    user?: UserInput;
    userName?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
}

export enum IdentificationType {
    RUC = 'RUC',
    DNI = 'CEDULA',
    FOREIGN_RUC = 'FOREIGN_RUC',
    FINAL_CONSUMER = 'FINAL_CONSUMER',
    PASSPORT = 'PASAPORTE',
    IDENTIFICATION_CARD = 'IDENTIFICATION_CARD',
}
export interface TypeIdentification {
    id?: string;
    name?: string;
}
