import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Permission, System } from 'src/app/model.ts/rol.model';

import { PermissionInput, SystemInput } from '../../../../model.ts/rol.model';
import { GeneratePermissionComponent } from '../generate-permission/generate-permission.component';
import { GeneratePermissionPresenter } from '../generate-permission/presenter/generate-permission.presenter';
import { PermissionsView } from './permissions.view';
import { PermissionsPresenter } from './presenter/permissions.presenter';

@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent
    extends AbstractView
    implements OnInit, AfterViewInit, PermissionsView
{
    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public dialogService: DialogService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private permissionsPresenter: PermissionsPresenter,
        private generatePermissionPresenter: GeneratePermissionPresenter
    ) {
        super(messageService, router);
        permissionsPresenter.view = this;

        if (!this.getAuth(['MANAGE_USERS'])) {
            this.showError('', 'Sin autorización');
            this.redirectTo('main');
        }
    }
    permissions: Permission[] = [];
    selectedPermission: Permission = {};
    page = 0;
    size = 12;
    totalElements = 0;
    value = '';
    activePermission = true;
    activePermissions = true;
    public ref;
    systems: System[];
    selectedSystem: System = {};
    numberPermissions = 0;

    systemAll: System = {
        id: null,
        name: 'TODOS',
        active: true,
        code: 'all',
    };

    ngOnInit(): void {
        this.selectedSystem = this.systemAll;
        this.listSystems();
        this.listPermissions();
    }

    ngAfterViewInit(): void {
        this.setFocusSearchPermission();
    }

    setFocusSearchPermission() {
        setTimeout(() =>
            this.renderer.selectRootElement('#searchPermission').focus()
        );
    }

    listSystems() {
        this.permissionsPresenter.listActiveSystem();
    }

    clickActivePermissionChecked() {
        this.listPermissions();
    }

    openGeneratePermissionComponent() {
        this.selectedPermission = {};
        this.showGeneratePermissionComponent(
            'Nuevo Permiso',
            true,
            this.selectedSystem.id ? this.selectedSystem : null
        );
    }

    editGeneratePermissionComponent(permission: Permission) {
        this.permissionsPresenter.permissionById(permission.id);
    }

    changePermissionActive(permission: Permission) {
        this.selectedPermission = permission;
        this.showChangeActivation();
    }

    showChangeActivation() {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${
                this.activePermission ? 'inactivar' : 'activar'
            } el permiso?`,
            header: `Confirme ${
                this.activePermission ? 'inactivación' : 'activación'
            }`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.changeActivatePermissionInput();
            },
            reject: () => {
                this.discardChangesActivatePermission();
            },
        });
    }

    discardChangesActivatePermission() {
        this.selectedPermission.active = this.selectedPermission.active
            ? false
            : true;
    }

    changeActivatePermissionInput() {
        let system: SystemInput = {
            id: this.selectedPermission.system.id,
            name: this.selectedPermission.system.name,
            code: this.selectedPermission.system.code,
            active: this.selectedPermission.system.active,
        };

        system = JSON.parse(JSON.stringify(system), this.omitTypename);

        let permission: PermissionInput = {
            id: this.selectedPermission.id,
            name: this.selectedPermission.name,
            domainAction: this.selectedPermission.domainAction,
            system,
            active: this.selectedPermission.active,
        };

        permission = JSON.parse(JSON.stringify(permission), this.omitTypename);

        this.permissionsPresenter.savePermission(permission);
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    showGeneratePermissionComponent(
        title: string,
        isNew: boolean,
        system: System
    ) {
        this.ref = this.dialogService.open(GeneratePermissionComponent, {
            header: title,
            closeOnEscape: true,
            width: '35%',
            height: 'auto',
            data: { permission: this.selectedPermission, isNew, system },
        });
        this.ref.onClose.subscribe(() => {
            this.listPermissions();
        });
    }

    paginate($event) {
        this.page = $event['page'];
        if (this.selectedSystem.id) {
            this.permissionsPresenter.listPermissionsBySystemId();
        } else {
            this.listPermissions();
        }
    }

    onSearchChange(searchValue: any): void {
        const searchLength = searchValue.target.value.length;
        // if (searchValue.keyCode === 13) {
        if (searchLength >= 3 || searchLength === 0) {
            this.value = searchValue.target.value;
            this.value = this.value.toUpperCase();
            this.listPermissions();
            // }
        }
    }

    listPermissions(): void {
        if (this.selectedSystem.code === 'all') {
            this.permissionsPresenter.listPermissions();
            this.permissionsPresenter.getNumberPermissions();
        } else {
            this.permissionsPresenter.listPermissionsBySystemId();
            this.permissionsPresenter.getNumberPermissionsBySystemId();
        }
    }
}
