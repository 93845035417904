import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { PersonService } from 'src/app/services/person.service';

import { PersonsView } from '../persons.view';

@Injectable()
export class PersonsPresenter implements Presenter {
    view: PersonsView;

    constructor(public personService: PersonService) {}

    listPersons(active: boolean) {
        console.log('funcion Lista Personas');
        this.view.blockUi();
        this.personService
            .listPersonsByStatus(
                this.view.page,
                this.view.size,
                this.view.value,
                active
            )
            .subscribe(
                ({ data }) => {
                    console.log(data);
                    if (data) {
                        this.view.persons = data.persons.data;
                        this.view.totalElements = data.persons.size;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen personas cargadas en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }

    getNumberPersons() {
        this.view.blockUi();
        this.personService
            .personsStatusCount(!this.view.activePerson)
            .subscribe(
                ({ data }) => {
                    if (data) {
                        this.view.numberPersons = data.personsStatusCount;
                    } else {
                        this.view.showWarn(
                            'Atención',
                            'No existen personas cargadas en la base de datos'
                        );
                    }
                    this.view.allowUi();
                },
                (error) => {
                    this.view.showError('Error', error);
                }
            );
    }
}
