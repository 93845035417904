import { Directive, Input } from '@angular/core';
import {
    AbstractControl,

    NG_VALIDATORS, ValidationErrors,

    Validator, ValidatorFn
} from '@angular/forms';
import { validateCedula } from './cedula';
import { validateRuc } from './ruc';

export function validateIdentification(): ValidatorFn {
    function validation(identification: string): boolean {

        const identificationLenght = identification.length;

        switch (identificationLenght) {
            case 10:
                return validateCedula(identification);
            case 13:
                return validateRuc(identification);
            // default:
            //     return validatePassport(identification);
        }
    }

    return (control: AbstractControl): ValidationErrors | null => {
        const identification = control.value as string;

        if (!control.value) {
            return null;
        }

        const valid = validation(identification);

        return valid ? null : { valid_identification: { value: control.value } };
    };
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[valid_identification]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: IdentificationValidatorDirective,
            multi: true,
        },
    ],
})
export class IdentificationValidatorDirective implements Validator {
    @Input('valid_identification') isActive: string;
    validate(control: AbstractControl): ValidationErrors | null {

        return validateIdentification()(control);
    }
}
