import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { SystemInput } from 'src/app/model.ts/rol.model';
import { SystemsRolesPermissionsService } from 'src/app/services/systems-roles-permissions.service';

import { GenerateSystemView } from '../generate-system.view';

@Injectable()
export class GenerateSystemPresenter implements Presenter {
    view: GenerateSystemView;

    constructor(
        private systemsRolesPermissionsService: SystemsRolesPermissionsService
    ) {}

    saveSystem(system: SystemInput) {
        this.view.blockUi();
        this.systemsRolesPermissionsService.saveSystem(system).subscribe(
            ({ data }) => {
                this.view.showSuccess('Sistema', 'Guardado con éxito');
                this.view.closeView();
            },
            (error) => {
                this.view.showError('Error', error);
            }
        );
    }
}
