// https://stackoverflow.com/questions/52052853/regex-not-working-in-directive-angular-4
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appInputTextAndNumber]',
})
export class TextNumberDirective {
    private regex: RegExp = new RegExp(/^[a-z A-Z0-9ñÑ.]*$/);

    // Allow key codes for special events
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [
        'Delete',
        'Backspace',
        'Tab',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowRight',
    ];
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
