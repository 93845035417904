import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { SystemInput } from 'src/app/model.ts/rol.model';

@Injectable({
    providedIn: 'root',
})
export class SystemsRolesPermissionsService {
    LOAD_SYSTEMS = gql`
        query systems(
            $page: Int!
            $size: Int!
            $value: String
            $active: Boolean!
        ) {
            systems(page: $page, size: $size, value: $value, active: $active) {
                page
                size
                data {
                    id
                    name
                    active
                    code
                }
            }
        }
    `;

    SAVE_SYSTEM = gql`
        mutation saveSystem($system: SystemInput!) {
            saveSystem(system: $system) {
                id
                name
                active
                code
            }
        }
    `;

    ROLES_BY_SYSTEM = gql`
        query rolBySystem(
            $page: Int!
            $size: Int!
            $active: Boolean
            $systemId: ID!
        ) {
            rolBySystem(
                page: $page
                size: $size
                active: $active
                systemId: $systemId
            ) {
                page
                size
                data {
                    id
                    name
                    active
                    permissions {
                        id
                        name
                        domainAction
                        active
                    }
                    system {
                        id
                        name
                        active
                        code
                    }
                }
            }
        }
    `;

    PERMISSIONS_BY_SYSTEM = gql`
        query permissionsBySystem($active: Boolean, $systemId: ID!) {
            permissionsBySystem(active: $active, systemId: $systemId) {
                id
                name
                domainAction
                active
                system {
                    id
                    name
                    active
                    code
                }
            }
        }
    `;

    SYSTEM_BY_ID = gql`
        query findSystemById($id: ID!) {
            findSystemById(id: $id) {
                id
                name
                active
                code
            }
        }
    `;

    constructor(private apollo: Apollo) {}

    systemById(id: string) {
        return this.apollo.query<any>({
            query: this.SYSTEM_BY_ID,
            variables: { id },
        });
    }
    saveSystem(system: SystemInput) {
        return this.apollo.mutate({
            mutation: this.SAVE_SYSTEM,
            variables: { system },
        });
    }

    listSystemsByStatus(
        page: number,
        size: number,
        value: string,
        active: boolean
    ) {
        return this.apollo.query<any>({
            query: this.LOAD_SYSTEMS,
            variables: { page, size, value, active },
        });
    }

    listRolesBySystemId(
        page: number,
        size: number,
        active: boolean,
        systemId: string
    ) {
        return this.apollo.query<any>({
            query: this.ROLES_BY_SYSTEM,
            variables: { page, size, active, systemId },
        });
    }

    listPermissionsBySystemId(active: boolean, systemId: string) {
        return this.apollo.query<any>({
            query: this.PERMISSIONS_BY_SYSTEM,
            variables: { active, systemId },
        });
    }
}
