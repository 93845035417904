<p-toast></p-toast>
<div class="topbar clearfix">
    <div class="col-12" style="position: fixed">
        <div class="">
            <div class="title">
                <label class="app-name title" id="title">ADMINISTRACIÓN PUNTOS DE VENTA</label>
            </div>
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="pt-2">
            <button pButton id="person-name" type="button" label="{{ currentPerson.fullName }}">
                <span class="material-icons mr-2" id="top-icon"> person_outline </span>
            </button>
            <button pButton type="button" icon="pi pi-angle-down" id="angle-down-ico"
                class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
            <p-menu styleClass="different" #menu [popup]="true" [model]="items"></p-menu>
        </div>
    </div>
</div>

<p-dialog header="Roles" [(visible)]="displayPosition" [position]="position" [modal]="true" [style]="{ width: '25vw' }"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ul class="list-group list-group-flush" *ngFor="let rol of rolesProfile">
        <li class="list-group-item">
            <label> ● &nbsp;{{ rol.name }}</label>
        </li>
    </ul>

    <!-- <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-times"
            (click)="displayPosition = false"
            label="Aceptar"
        ></p-button>
    </ng-template> -->
</p-dialog>

<p-dialog header="Configuración Dynamics" [(visible)]="displayConfigDynamics" [modal]="true" [style]="{ width: '50vw' }"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <form [formGroup]="frmConfigDynamics" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Tipo</label>
                    <input type="text" formControlName="type" maxlength="120" class="form-control" pInputText
                        autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.type.errors }" />
                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                        <div *ngIf="f.type.errors.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Tipo</label>
                    <input type="text" formControlName="grantType" maxlength="120" class="form-control" pInputText
                        autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.grantType.errors }" />
                    <div *ngIf="submitted && f.grantType.errors" class="invalid-feedback">
                        <div *ngIf="f.grantType.errors.required">Campo requerido</div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <label>Cliente</label>
                    <input type="text" formControlName="clientId" maxlength="120" class="form-control" pInputText
                        autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }" />
                    <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                        <div *ngIf="f.clientId.errors.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Secret</label>
                    <input type="text" formControlName="clientSecret" maxlength="120" class="form-control" pInputText
                        autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.clientSecret.errors }" />
                    <div *ngIf="submitted && f.clientSecret.errors" class="invalid-feedback">
                        <div *ngIf="f.clientSecret.errors.required">Campo requerido</div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <label>Resource</label>
                    <input type="text" formControlName="resource" maxlength="120" class="form-control" pInputText
                        autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.resource.errors }" />
                    <div *ngIf="submitted && f.resource.errors" class="invalid-feedback">
                        <div *ngIf="f.resource.errors.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="row justify-content-end pr-3">
                <button pButton class="bc-primary">Guardar</button>
            </div>
        </div>
    </form>
</p-dialog>

<p-dialog header="Atención" [(visible)]="displaySyncStock" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="col">
        <div class="row">
            <label for="">¿Desea sincronizar productos de Dynamics?</label>
        </div>
        <div class="row justify-content-end pt-3 pr-3">
            <p-button (click)="displaySyncStock=false" label="Cancelar"
                class="p-button-secondary material-icons pr-3"></p-button>
            <p-button (click)="syncStockDynamics()" label="Aceptar" class="bc-primary material-icons"></p-button>
        </div>
    </div>
</p-dialog>