import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AbstractView } from 'src/app/core/abstract_view';
import { Permission, PermissionInput, System } from 'src/app/model.ts/rol.model';

import { GeneratePermissionView } from './generate-permission.view';
import { GeneratePermissionPresenter } from './presenter/generate-permission.presenter';

@Component({
    selector: 'app-generate-permission',
    templateUrl: './generate-permission.component.html',
    styleUrls: ['./generate-permission.component.scss'],
})
export class GeneratePermissionComponent
    extends AbstractView
    implements OnInit, AfterViewInit, GeneratePermissionView
{
    selectedPermission: PermissionInput = {};
    cols: any[];
    errorMessage: string;
    permissionForm = this.formBuilder.group({});
    permission?: Permission;
    value = '';
    permissions: Permission[];
    systems: System[] = [];
    checked = false;

    isNew;
    selectedSystem: System;

    constructor(
        public router: Router,
        protected renderer: Renderer2,
        public ref: DynamicDialogRef,
        public formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private generatePermissionPresenter: GeneratePermissionPresenter
    ) {
        super(messageService, router);
        generatePermissionPresenter.view = this;
        this.selectedPermission = config.data.permission;
        this.isNew = config.data.isNew;
        this.selectedSystem = config.data.system;
    }

    ngOnInit(): void {
        this.listSystems();
        this.validateSelectedPermission();
    }

    ngAfterViewInit(): void {
        this.setFocusName();
    }

    setFocusName() {
        setTimeout(() => this.renderer.selectRootElement('#name').focus());
    }

    validateSelectedPermission() {
        if (this.isNew) {
            this.setNewPermissionToForm();
        } else {
            this.setPermissionToForm();
        }
    }

    setNewPermissionToForm() {
        this.permissionForm = this.formBuilder.group({
            system: new FormControl(this.selectedSystem, [Validators.required]),
            name: new FormControl('', [Validators.required]),
            domainAction: new FormControl('', [Validators.required]),
            active: new FormControl(true),
        });
    }

    setPermissionToForm() {
        this.permissionForm = this.formBuilder.group({
            system: new FormControl(this.selectedPermission.system, [
                Validators.required,
            ]),
            name: new FormControl(this.selectedPermission.name, [
                Validators.required,
            ]),
            domainAction: new FormControl(
                this.selectedPermission.domainAction,
                [Validators.required]
            ),
            active: new FormControl(this.selectedPermission.active, [
                Validators.required,
            ]),
        });
        // this.permissionForm.get('domainAction').disable();
    }

    listSystems() {
        this.generatePermissionPresenter.listSystems();
    }

    validatePermission() {
        this.markFormGroupTouched(this.permissionForm);

        if (this.permissionForm.invalid) {
            this.showMessage('Información incompleta');
        } else {
            this.permissionForm.get('domainAction').enable();
            let permission: PermissionInput = {
                id: this.selectedPermission?.id
                    ? this.selectedPermission.id
                    : null,
                system: this.permissionForm.value.system,
                name: this.permissionForm.value.name.toUpperCase(),
                domainAction:
                    this.permissionForm.value.domainAction.toUpperCase(),
                active: this.permissionForm.value.active,
            };

            permission = JSON.parse(
                JSON.stringify(permission),
                this.omitTypename
            );

            this.showConfirmation(permission);
        }
    }

    /* Marks all controls in a form group as touched
       @param formGroup - The form group to touch    */
    private markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    private omitTypename(key, value) {
        return key === '__typename' ? undefined : value;
    }

    orderRolesSelected(rolesSelected: any) {
        throw new Error('Method not implemented.');
    }

    showConfirmation(permission: PermissionInput) {
        this.confirmationService.confirm({
            acceptLabel: 'Si',
            message: `Desea ${this.isNew ? 'guardar' : 'actualizar'} el permiso?`,
            header: `Confirme ${this.isNew ? 'acción' : 'actualización'}`,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.generatePermissionPresenter.savePermission(permission);
            },
        });
    }

    compareFn(c1: System, c2: System): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    closeView() {
        this.ref.close();
    }
}
